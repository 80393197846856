// Created by:      Marcel Simma
// Copyright:       © 2020 Zewas OG
// Creation date:   02.08.2020
// Last edited:     02.08.2020

import { useLazyQuery } from "@apollo/client";
import queryString from "query-string";
import { useEffect } from "react";
import { ThreeDots } from "react-loader-spinner";
import Brand from "../../assets/images/landing/Brand.jpg";
import image1 from "../../assets/images/landing/gutgereift_landing1-min.jpg";
import image2 from "../../assets/images/landing/gutgereift_landing2-min.jpg";
import image3 from "../../assets/images/landing/gutgereift_landing3-min.jpg";
import StyledButton from "../../components/StyledButton";
import {
  colors,
  constants,
  Cookies,
  GetUserContext,
  gql,
  React,
  styl,
  styled,
  useContext,
  useMutation,
  useState,
} from "../../imports";

const cookies = new Cookies();

var images = [image1, image2, image3];
var actualImage = Math.floor(Math.random() * Math.floor(3));

const LandingpageSubbesteller = (urlParameter) => {
  const GetInitiatorName = gql`
    query getInitiatorNameQuery($key: String!) {
      __typename
      initiatorInfo(subordererKey: $key) {
        bank
        iban
        name
      }
    }
  `;
  const loginSubbestellerMutation = gql`
    mutation MyMutation($key: String!) {
      __typename
      loginSuborderer(input: { clientMutationId: "", subordererKey: $key }) {
        authToken
        refreshToken
      }
    }
  `;

  const [name, setName] = useState("");
  let params = queryString.parse(window.location.search);
  const key2 = encodeURIComponent(params.id);
  const type = decodeURIComponent(params.type);
  const [user, setUser] = useContext(GetUserContext);

  const key = decodeURIComponent(key2);

  // const { loading, error, data: content } = useLazyQuery(GetInitiatorName, {
  //     variables: { key }, onCompleted: (Name) => {
  //         setName(Name.initiatorInfo.name)
  //     }
  // });
  const [getInitiatorInfo, { loading, error, data: content }] =
    useLazyQuery(GetInitiatorName);

  useEffect(() => {
    if (type != "inviteLink") {
      cookies.remove("AuthToken", { path: "/" });
      cookies.remove("RefreshToken", { path: "/" });
    }

    getInitiatorInfo({ variables: { key } });
  }, []);
  useEffect(() => {
    if (content) {
      setName(content?.initiatorInfo.name);
    }
  }, [content]);

  const [LoginSuborderer, { error: mutationError, loading: mutationLoading }] =
    useMutation(loginSubbestellerMutation);

  let link =
    "/dateneingabeSubbesteller?id=" +
    key2 +
    "&name=" +
    name +
    "&type=" +
    params.type;

  const createAccount = () => {
    if (params.type === "noaccount") {
      setUser(null);
      cookies.remove("AuthToken");
      cookies.remove("RefreshToken");
      LoginSuborderer({ variables: { key: key } })
        .then((response) => {
          cookies.set("AuthToken", response.data.loginSuborderer.authToken, {
            path: "/",
          });
          cookies.set(
            "RefreshToken",
            response.data.loginSuborderer.refreshToken,
            { path: "/" }
          );
          window.location.href = link;
        })
        .catch((e) => {
          alert(e.toString());
        });
    } else {
      setUser(null);
      cookies.remove("AuthToken", { path: "/" });
      cookies.remove("RefreshToken", { path: "/" });
      window.location.href = link;
    }
  };
  return (
    <SplitScreenContainer>
      <SplitScreenRight>
        {/* <Quote
                    mainText="Mear luag&#244;d druf"
                    description="Wir schauen drauf"
                    color="LIGHT"
                /> */}
      </SplitScreenRight>
      <SplitScreenLeft>
        <Content>
          <StyledBrand src={Brand} />
          <Padding>
            <StyledText>
              <p style={{ fontWeight: 800 }}>
                {name || (
                  <ThreeDots
                    height={200}
                    width={100}
                    color={colors.YellowBrigthButton}
                  />
                )}{" "}
              </p>
              hat dich eingeladen bei der Gruppenbestellung auf{" "}
              <a
                href="https://www.gutgereift.at"
                style={{ color: colors.YellowBrigthButton }}
              >
                gutgereift.at
              </a>{" "}
              mitzumachen.
            </StyledText>
          </Padding>
          <a>
            <StyledButton
              title={"Bestellung erfassen"}
              onClick={() => {
                createAccount();
              }}
              fontSize={"25px"}
              color={colors.YellowBrigthButton}
              scale={"BIG"}
            />
          </a>
        </Content>
      </SplitScreenLeft>
    </SplitScreenContainer>
  );
};

const SplitScreenContainer = styled.div`
  min-height: 100vh;
  height: auto;
  width: 100%;
  ${styl.flexRow};
  flex-flow: row-reverse;

  @media (max-width: ${constants.nexusPortraitWidth}px) {
    ${styl.flexColumn};
    flex-flow: column-reverse;
    justify-content: center;
    align-items: flex-end;
  }
`;

const Padding = styled.div`
  padding: 40px 0;
  @media (max-width: ${constants.nexusPortraitWidth}px) {
    padding-top: 0;
  }
`;

const SplitScreenLeft = styled.div`
  width: 50%;
  min-height: 100vh;
  height: 100%;
  height: 540px;
  ${styl.flexColumn};
  justify-content: center;
  align-items: flex-end;

  @media (max-width: ${constants.nexusPortraitWidth}px) {
    min-height: 50vh;
    height: auto;
    width: 100%;
    ${styl.flexRow};
    justify-content: center;
    align-items: flex-end;
  }
`;

const StyledBrand = styled.img`
  width: 90%;
  height: auto;
  @media (max-width: 1000px) {
    width: 300px;
    margin-bottom: 50px;
  }
`;

const SplitScreenRight = styled.div`
  flex: 1;
  min-height: 100vh;
  height: 540px;
  background-image: url(${images[actualImage]});
  background-size: cover;
  background-position: top;
  padding: 3%;
  box-sizing: border-box;

  @media (max-width: ${constants.nexusPortraitWidth}px) {
    height: 50vh;
    min-height: 200px;
    width: 100%;
    padding: 20px 10px;
    ${styl.flexRow};
    ${styl.flexCenter};
  }
`;

const StyledText = styled.p`
  font-family: "Montserrat", sans-serif;
  font-weight: 400;
  color: ${colors.SolidGreyText};
  font-size: 16px;
  line-height: 22px;

  @media (max-width: ${constants.nexusPortraitWidth}px) {
    text-align: center;
  }
`;

const Content = styled.div`
  padding: 8%;
  max-width: 312px;
  min-width: 233px;
  width: 84%;

  @media (max-width: ${constants.nexusPortraitWidth}px) {
    ${styl.flexColumn};
    ${styl.flexCenter};
    padding: 30px;
    margin-bottom: -62.5px;
  }
`;

export default LandingpageSubbesteller;
