// Created by:      Marcel Simma
// Copyright:       © 2020 Zewas OG
// Creation date:   02.08.2020
// Last edited:     02.08.2020

import PropTypes from "prop-types";
import React from "react";
import { useLocation } from "react-router-dom";
import styled from "styled-components";
import colors from "../../constants/colors";
import constants from "../../constants/constants";
import styl from "../../styl";

// Props:
// - href: String (required)
// -- The link to another page.
// - icon: String (not required)
// -- Contains a picture to be displayed in front of the text.
// - text: String (required)
// -- A word that tells the user what he will see when he clicks on the link.
// - fontWeight: String (not required)
// -- This can be used to set the font-weight of the link to bold.  By default the font-weight is light.

// Example: <NavigationItem href={"/philosophy"} text={"Philosophie"} fontWeight={"bold"} icon={LoginIcon}/>

const NavigationItem = ({
  href = "/",
  icon,
  text,
  fontWeight = "light",
  alignment,
}) => {
  return (
    <Container
      prop_icon={icon}
      className={useLocation().pathname == href ? "sel" : null}
      href={href}
    >
      <Text
        prop_icon={icon}
        $prop_fontWeight={fontWeight}
        prop_alignment={alignment}
      >
        {icon ? <Icon prop_icon={icon} /> : null}
        {text}
      </Text>
    </Container>
  );
};

NavigationItem.propTypes = {
  href: PropTypes.string.isRequired,
  icon: PropTypes.string,
  text: PropTypes.string.isRequired,
  fontWeight: PropTypes.string,
  alignment: PropTypes.string,
};

const Container = styled.a`
  ${styl.clear};
  ${styl.flexRow};
  align-items: center;
  box-sizing: border-box;
  ${styl.transition};
  margin: 0px;
  cursor: pointer;
  height: 45px;

  &.sel {
    p {
      font-weight: 600;
    }
  }

  &:active,
  &:hover {
    p {
      color: ${colors.CheninYellowHoverButton};
    }
  }

  @media (max-width: ${constants.nexusPortraitWidth}px) {
    ${styl.flexColumn};
    ${styl.flexCenter};

    width: 90%;
    border-bottom: 1px solid ${colors.MercuryGreyInputfields};

    &:last-child {
      border-bottom: none;
    }
  }
`;

const Text = styled.p`
  ${styl.flexRow};

  ${styl.clear};
  ${styl.transition};
  color: ${colors.SolidGreyText};
  ${(props) =>
    props.$prop_fontWeight == "bold"
      ? styl.boldDefaultFont
      : styl.lightDefaultFont}
  font-size: 15px;
  text-transform: uppercase;
  justify-content: ${(props) =>
    props.prop_alignment == "right" ? "flex-end" : "center"};
  /* text-align: center; */
  color: #4d4d4d;
  text-align: right;
  /* width: 291px; */
  font-size: 16px;
  letter-spacing: 0.4px;
  text-transform: uppercase;
  width: ${(props) => (props.prop_alignment == "right" ? "100%" : "unset")};
  @media (max-width: 1100px) {
    font-size: 10px;
  }
  @media (max-width: 860px) {
    font-size: 8px;
  }

  @media (max-width: ${constants.nexusPortraitWidth}px) {
    font-size: 14px;
  }
`;

const Icon = styled.div`
  ${styl.clear};
  height: 24px;
  width: 24px;
  background: url(${(props) => props.prop_icon});
  background-size: contain;
  background-position: center;
  margin-right: 10px;
`;

export default NavigationItem;
