import { gql, useLazyQuery, useMutation, useQuery } from "@apollo/client";
import React, { useContext, useEffect, useState } from "react";
import { ThreeDots } from "react-loader-spinner";
import styled from "styled-components";
import Cookies from "universal-cookie";
import AddProduct from "../../components/AddProduct";
import Alert from "../../components/Alert";
import InviteList from "../../components/inviteList/InviteList";
import OrderContainer from "../../components/OrderContainer";
import OrderOverview from "../../components/OrderOverview";
import StyledButton from "../../components/StyledButton";
import ThanksScreen from "../../components/ThanksScreen";
import UserData from "../../components/UserData";
import colors from "../../constants/colors";
import { GetCurrentPage, GetUserContext } from "../../localStorage/Store";
import Error404 from "../error/Error404";
import queryString from "query-string";

const MultipleBuyerPage = () => {
  const ExtCheckout = gql`
    mutation MyMutation($coupon: String, $coolbox: Boolean) {
      __typename
      extCheckout(
        input: {
          clientMutationId: "ExtCheckout"
          coupon: $coupon
          coolboxdisabled: $coolbox
        }
      ) {
        order {
          discountTotal
          total
          paymentMethod
          shippingTotal
          subtotal
          totalTax
          orderKey
          orderId
          cartTax
          customerIpAddress
          orderNumber
          customer {
            bank
            firstName
            email
            lastName
            id
            customerId
          }
          billing {
            address1
            city
            country
            email
            firstName
            lastName
            phone
            postcode
            state
          }
          shipping {
            address1
            city
            company
            country
            email
            firstName
            lastName
            postcode
            state
          }
          lists {
            total
            lists {
              name
              total
              orderers {
                name
                products {
                  amount
                  total
                  rowId
                  product {
                    name
                    price
                    weight
                    metaData {
                      key
                      value
                    }
                    parent {
                      name
                    }
                  }
                }
                total
              }
            }
          }
          lineItems {
            nodes {
              product {
                parent {
                  name
                }
                name
              }
              variation {
                name
                price
                weight
                variationId
                sku
              }
              metaData {
                key
                value
              }
              quantity
              total
              subtotal
            }
          }
          couponLines {
            nodes {
              discount
            }
          }
        }
      }
    }
  `;
  const getAllProducts = gql`
    query getProducts {
      __typename
      products(
        where: { status: "publish", orderby: { field: DATE, order: ASC } }
        first: 20
      ) {
        nodes {
          name
          image {
            sourceUrl
          }
          e2_shortDescription {
            shortdescription
          }
          ... on VariableProduct {
            variations {
              nodes {
                weight
                sku
                price
                variationId
              }
            }
          }
          productId
        }
      }
    }
  `;

  const GetExtCartItems = gql`
    query getExtCartItems {
      __typename
      extCart {
        addProductsUntil
        lists {
          name
          total
          orderers {
            products {
              amount
              rowId
              total
              product {
                parent {
                  name
                  coolbox {
                    coolboxActivated
                    coolboxWeight
                  }
                }
                name
                price
                weight
                variationId
                id
              }
            }
            name
            total
          }
        }
        total
        coolbox
        shipping
        subtotalWithTax
        minimumOrderWeight
        totalWeight
      }
    }
  `;
  const RemoveItemFromExtCart = gql`
    mutation removeItemsFromExtCart($keys: Int!, $subordererKey: String!) {
      __typename
      removeItemsFromExtCart(
        input: {
          clientMutationId: "RemoveItems"
          rowId: $keys
          subordererKey: $subordererKey
        }
      ) {
        lists {
          name
          total
          orderers {
            products {
              amount
              rowId
              total
              product {
                parent {
                  name
                }
                name
                price
                weight
                variationId
                id
              }
            }
            name
            total
          }
        }
        total
      }
    }
  `;
  const UpdateCustomerMutation = gql`
    mutation updateCustomer($id: ID, $agb: String, $dsgvo: String) {
      __typename
      updateCustomer(
        input: {
          clientMutationId: "UpdateCustomer"
          id: $id
          agb: $agb
          dsgvo: $dsgvo
        }
      ) {
        customer {
          agb
          dsgvo
          billing {
            firstName
            address1
            city
            country
            email
            lastName
            phone
            postcode
            state
          }
          shipping {
            address1
            city
            postcode
            state
            country
            firstName
            lastName
            email
            phone
          }
          hasCoupon
          lastName
          firstName
          email
          customerId
          id
          username
          iban
          bank
          showName
          showPrice
        }
      }
    }
  `;
  const GetagbversionQuery = gql`
    query MyQuery {
      __typename
      generalOptions {
        agb {
          agbversion
        }
        dsgvo {
          dsgvoversion
        }
      }
    }
  `;
  const CheckAGBMutation = gql`
    query MyQuery {
      checkAgb {
        agb
        dsgvo
      }
    }
  `;
  const cookies = new Cookies();
  const [agbVersion, setAGBVersion] = useState("");
  const [dsgvoVersion, setDSGVOVersion] = useState("");

  const { loading, error, data } = useQuery(getAllProducts);
  const {
    loading2,
    error2,
    data: extCart,
  } = useQuery(GetExtCartItems, { fetchPolicy: "network-only" });
  const [
    removeItemsFromExtCart,
    { loading: mutationLoading2, error: mutationError2 },
  ] = useMutation(RemoveItemFromExtCart);
  const [
    checkAGBMutation,
    { loading: loading1, error: error1, data: checkAGBResponse },
  ] = useLazyQuery(CheckAGBMutation);
  const {
    loading: loading3,
    error: error3,
    data: agbData,
  } = useQuery(GetagbversionQuery, {
    onCompleted: (AGBVersion) => {
      setAGBVersion(AGBVersion.generalOptions.agb.agbversion);
      setDSGVOVersion(AGBVersion.generalOptions.dsgvo.dsgvoversion);
    },
  });
  const [updateCustomer, { loading: mutationLoading1, error: mutationError1 }] =
    useMutation(UpdateCustomerMutation);
  const [showAlert, setShowAlert] = useState(false);
  const [user, setUser] = useContext(GetUserContext);
  const { currentPage, setCurrentPage, order, setOrder } =
    useContext(GetCurrentPage);
  const { duration, setDuration, durationFromBackend, setDurationFromBackend } =
    useContext(GetCurrentPage);

  const [saveOrder, { loading: mutationLoading, error: mutationError }] =
    useMutation(ExtCheckout);
  const navigation = [
    { actualName: ["Benutzerdaten", "Bestellcommunity", "Bestellen"] },
    { codeName: ["UserData", "Invite", "AddProduct"] },
  ];

  function SaveOrder(coupon, coolbox) {
    let Array = [];
    // content.extCart?.products.map((item) => {
    //   Array.push(fillList(item))
    // })
    saveOrder({ variables: { coupon, coolbox: !coolbox } })
      .then((response) => {
        // Array.map(async (item, index) =>{

        //   await removeSelected(index)
        // })

        setOrder(response.data.extCheckout.order);
        return true;
      })
      .catch((error) => {
        alert(error.toString());
      });
  }
  useEffect(() => {
    if (!duration) {
      setDuration(5);
    }
  }, []);
  useEffect(() => {
    let params = queryString.parse(window.location.search);

    if (extCart) {
      if (
        extCart.extCart.addProductsUntil !== 0 &&
        extCart.extCart.addProductsUntil !== null
      ) {
        let date = new Date(extCart.extCart?.addProductsUntil * 1000);
        let currentDate = new Date();
        // let day = parseInt(Math.round(date.getTime() - currentDate.getTime())/ (1000 * 60 * 60 * 24))|| 0
        let tmpTime = date.getTime() - currentDate.getTime();
        var tmpDays = tmpTime / (1000 * 3600 * 24);
        if (tmpDays < 0) {
          setDuration("Bestelldauer ist abgelaufen.");
        } else if (tmpDays < 0.5) {
          setDuration(date.getHours() + ":" + date.getMinutes() + " Uhr");
        } else {
          setDuration(Math.round(tmpDays));
        }
        setDurationFromBackend(true);
      } else {
        setDurationFromBackend(false);
      }

      if (!currentPage) {
        if (params.location === "single") {
          setCurrentPage("Invite");
        } else {
          if (user.orderCount > 0) {
            if (extCart.extCart.lists.length > 0) {
              setCurrentPage("AddProduct");
            } else {
              setCurrentPage("Invite");
            }
          } else {
            if (extCart.extCart.lists.length > 0) {
              setCurrentPage("AddProduct");
            } else {
              setCurrentPage("UserData");
            }
          }
        }
      }
    }
  }, [extCart]);

  useEffect(() => {
    checkAGBMutation();
  }, []);

  const handleCheckAGB = () => {
    updateCustomer({
      variables: { id: user.id, agb: agbVersion, dsgvo: dsgvoVersion },
    }).then((response) => {
      setShowAlert(false);
    });
  };
  const handleView = (pageName) => {
    setCurrentPage(pageName);
  };

  if (error || error2 || mutationError || mutationError2) return <Error404 />;
  if (loading || loading2 || mutationLoading || mutationLoading2)
    return (
      <div style={{ display: "flex", justifyContent: "center" }}>
        <ThreeDots height={400} width={200} color={colors.YellowBrigthButton} />
      </div>
    );

  return (
    <React.Fragment>
      <OrderContainer
        activeView={(pageName) => handleView(pageName)}
        currentPage={currentPage}
        navigation={navigation}
        clickable={true}
        minheight4k={800}
      >
        {currentPage === "UserData" ? (
          <UserData
            activeView={(pageName) => handleView(pageName)}
            multipleBuyer={true}
            location="MultipleBuyerPage"
            parentPage="MultipleBuyerPage"
          />
        ) : currentPage === "AddProduct" ? (
          <AddProduct
            data={data?.products.nodes}
            subordererKey={""}
            parentPage={"MultipleBuyerPage"}
            saveOrder={() => {}}
            activeView={(pageName) => handleView(pageName)}
          ></AddProduct>
        ) : currentPage === "Invite" ? (
          <InviteList
            activeView={(pageName) => handleView(pageName)}
          ></InviteList>
        ) : currentPage === "OrderOverview" ? (
          <OrderOverview
            parentPage={"MultipleBuyerPage"}
            subordererKey={""}
            saveOrder={SaveOrder}
            activeView={(pageName) => handleView(pageName)}
          />
        ) : currentPage === "OrderComplete" && order ? (
          // <OrderComplete order={order} />
          <>
            <ThanksScreen order={order} location={"MultipleBuyerPage"} />
          </>
        ) : (
          <div style={{ display: "flex", justifyContent: "center" }}>
            <ThreeDots
              height={400}
              width={200}
              color={colors.YellowBrigthButton}
            />
          </div>
        )}
        {showAlert && user && (
          <Alert isVisible={true}>
            <p>
              Ich habe die{" "}
              <Link
                target="_blank"
                href="../agb"
                style={{ textDecoration: "underline" }}
              >
                AGBs
              </Link>{" "}
              gelesen und akzeptiere diese.
            </p>
            <p>
              Ich habe die{" "}
              <Link
                target="_blank"
                href="../datenschutz"
                style={{ textDecoration: "underline" }}
              >
                Datenschutzerklärung
              </Link>{" "}
              gelesen und akzeptiere diese.
            </p>
            <StyledButton
              // loading={mutationLoading}
              scale={"ORDER"}
              title={"Bestätigen"}
              color={colors.YellowBrigthButton}
              fontSize={"20px"}
              onClick={() => {
                handleCheckAGB();
              }}
            />
            <StyledButton
              // loading={mutationLoading}
              scale={"ORDER"}
              title={"Abbrechen"}
              color={colors.YellowBrigthButton}
              fontSize={"20px"}
              onClick={() => {
                // handleCheckAGB();
                setUser(null);
                cookies.remove("AuthToken", { path: "/" });
                cookies.remove("RefreshToken", { path: "/" });
                window.location.href = "./home";
                setShowAlert(false);
              }}
            />
          </Alert>
        )}
      </OrderContainer>
    </React.Fragment>
  );
};

const Link = styled.a`
  color: #b2982a;
  text-decoration: none;
  z-index: 300;
`;

export default MultipleBuyerPage;
