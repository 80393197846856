import React, { useState } from "react";
import { Gallery } from "react-grid-gallery";
import styled from "styled-components";
import AttitudePNG from "../../assets/images/attitude.png";
import AttitudePNG2 from "../../assets/images/attitude2.png";
import HeadingOne from "../../components/HeadingOne";
import HeadingTwo from "../../components/HeadingTwo";
import PictureText from "../../components/PictureText";
import Quote from "../../components/Quote";
import colors from "../../constants/colors";
import styl from "../../styl";
import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css";

const IMAGES = [
  {
    src: "https://api.gutgereift.at/app/uploads/2020/10/haltung_2.jpg",
    thumbnail: "https://api.gutgereift.at/app/uploads/2020/10/haltung_2.jpg",
  },
  {
    src: "https://api.gutgereift.at/app/uploads/2020/10/haltung_1.jpg",
    thumbnail: "https://api.gutgereift.at/app/uploads/2020/10/haltung_1.jpg",
  },
  {
    src: "https://api.gutgereift.at/app/uploads/2020/10/haltung_3.jpg",
    thumbnail: "https://api.gutgereift.at/app/uploads/2020/10/haltung_3.jpg",
  },
  {
    src: "https://api.gutgereift.at/app/uploads/2020/10/haltung_4.jpg",
    thumbnail: "https://api.gutgereift.at/app/uploads/2020/10/haltung_4.jpg",
  },
  {
    src: "https://api.gutgereift.at/app/uploads/2020/10/haltung_7.jpg",
    thumbnail: "https://api.gutgereift.at/app/uploads/2020/10/haltung_7.jpg",
  },
  {
    src: "https://api.gutgereift.at/app/uploads/2020/10/haltung_6.jpg",
    thumbnail: "https://api.gutgereift.at/app/uploads/2020/10/haltung_6.jpg",
  },
  {
    src: "https://api.gutgereift.at/app/uploads/2020/10/haltung_9.jpg",
    thumbnail: "https://api.gutgereift.at/app/uploads/2020/10/haltung_9.jpg",
  },
  {
    src: "https://api.gutgereift.at/app/uploads/2020/10/haltung_8.jpg",
    thumbnail: "https://api.gutgereift.at/app/uploads/2020/10/haltung_8.jpg",
  },
  {
    src: "https://api.gutgereift.at/app/uploads/2020/10/haltung_11.jpg",
    thumbnail: "https://api.gutgereift.at/app/uploads/2020/10/haltung_11.jpg",
  },
  {
    src: "https://api.gutgereift.at/app/uploads/2020/10/haltung_15.jpg",
    thumbnail: "https://api.gutgereift.at/app/uploads/2020/10/haltung_15.jpg",
  },
  {
    src: "https://api.gutgereift.at/app/uploads/2020/10/haltung_12.jpg",
    thumbnail: "https://api.gutgereift.at/app/uploads/2020/10/haltung_12.jpg",
  },
  {
    src: "https://api.gutgereift.at/app/uploads/2020/10/haltung_14.jpg",
    thumbnail: "https://api.gutgereift.at/app/uploads/2020/10/haltung_14.jpg",
  },
  {
    src: "https://api.gutgereift.at/app/uploads/2020/10/haltung_13.jpg",
    thumbnail: "https://api.gutgereift.at/app/uploads/2020/10/haltung_13.jpg",
  },
  {
    src: "https://api.gutgereift.at/app/uploads/2020/10/haltung_17.jpg",
    thumbnail: "https://api.gutgereift.at/app/uploads/2020/10/haltung_17.jpg",
  },
  {
    src: "https://api.gutgereift.at/app/uploads/2020/10/haltung_5.jpg",
    thumbnail: "https://api.gutgereift.at/app/uploads/2020/10/haltung_5.jpg",
  },
  {
    src: "https://api.gutgereift.at/app/uploads/2020/10/haltung_18.jpg",
    thumbnail: "https://api.gutgereift.at/app/uploads/2020/10/haltung_18.jpg",
  },
  {
    src: "https://api.gutgereift.at/app/uploads/2020/10/haltung_20.jpg",
    thumbnail: "https://api.gutgereift.at/app/uploads/2020/10/haltung_20.jpg",
  },
];

export default function AttitudePage() {
  const [index, setIndex] = useState(-1);

  const currentImage = IMAGES[index];
  const nextIndex = (index + 1) % IMAGES.length;
  const nextImage = IMAGES[nextIndex] || currentImage;
  const prevIndex = (index + IMAGES.length - 1) % IMAGES.length;
  const prevImage = IMAGES[prevIndex] || currentImage;

  const handleClick = (index, item) => setIndex(index);
  const handleClose = () => setIndex(-1);
  const handleMovePrev = () => setIndex(prevIndex);
  const handleMoveNext = () => setIndex(nextIndex);

  return (
    <Main>
      <ContentContainer>
        <TopWrapper>
          <HeadingOne title="Haltung" />
          <QuoteDiv>
            <Quote
              mainText={["Wenn er eines kann,", <br />, "kann er alles."]}
              description="Franz Michael Felder, 1839 - 1869"
            />
          </QuoteDiv>
        </TopWrapper>
        <ParagraphMobile>
          Die Bregenzerwälder Kulturlandschaft verdankt ihre Schönheit der
          konkreten Arbeit der Menschen. Ein sorgfältiger Umgang mit den
          Pflanzen und Tieren, dem Wissen ums Material, das gehört hier zum
          Kulturgut des Handwerks. „Wenn er eines kann, kann er alles.“
        </ParagraphMobile>
        <PictureTextDiv>
          <MobileWrapper>
            <PictureText
              text={
                "Die Rede ist von einem Senn, welcher gleich einem Tausendkünstler ein Handwerker, Bauer, Philosoph und Geschäftsmann in Einem ist. So denken auch wir. Die jahreszeitlichen Kreisläufe, von der Bewirtschaftung und Produktion bis hin zur Vermarktung, im engen und ehrlichen Austausch mit Lieferanten und Kunden zu beachten, das ist unser Anliegen."
              }
              picture={AttitudePNG}
              format={"row"}
            ></PictureText>
          </MobileWrapper>
          <DesktopWrapper>
            <PictureText
              text={
                "Die Bregenzerwälder Kulturlandschaft verdankt ihre Schönheit der konkreten Arbeit der Menschen. Ein sorgfältiger Umgang mit den Pflanzen und Tieren, dem Wissen ums Material, das gehört hier zum Kulturgut des Handwerks. „Wenn er eines kann, kann er alles.“ Die Rede ist von einem Senn, welcher gleich einem Tausendkünstler ein Handwerker, Bauer, Philosoph und Geschäftsmann in Einem ist. So denken auch wir. Die jahreszeitlichen Kreisläufe, von der Bewirtschaftung und Produktion bis hin zur Vermarktung, im engen und ehrlichen Austausch mit Lieferanten und Kunden zu beachten, das ist unser Anliegen."
              }
              picture={AttitudePNG}
              format={"row"}
            ></PictureText>
          </DesktopWrapper>

          <PictureText
            text={
              "Bei der Entwicklung unserer Bildmarke haben wir die Brücke vom Käse zum Auge geschlagen. Ein Auge auf die Dinge zu haben, das ist unser Anspruch und Ansporn. Unser Produktsortiment ist übersichtlich und gut selektiert. Wir kennen unsere Partner persönlich und legen den Fokus auf umsichtige, handwerkliche Herstellung. Mit der Nutzung der Digitalisierung für Communities, stellen wir auch auf dieser Ebene persönliche Kontakte sicher. Wir sind überzeugt, dass kleine, überschaubare Strukturen erhaltenswert sind und den Weg in die Zukunft weisen. Unsere Entscheidungsgrundlagen sind gut gereift und entsprechen persönlichen Grundsätzen: Maßhalten und ein ehrliches Miteinander."
            }
            picture={AttitudePNG2}
            format={"row-reverse"}
          ></PictureText>
        </PictureTextDiv>
        <GalleryContainer>
          <HeadingTwo title="Bildergalerie" color="DEFAULT" />
          <GalleryWrapper>
            <Gallery
              images={IMAGES}
              enableImageSelection={false}
              onClick={handleClick}
            />
            {!!currentImage && (
              /* @ts-ignore */
              <Lightbox
                mainSrc={currentImage.src}
                mainSrcThumbnail={currentImage.thumbnail}
                nextSrc={nextImage.src}
                nextSrcThumbnail={nextImage.thumbnail}
                prevSrc={prevImage.src}
                prevSrcThumbnail={prevImage.thumbnail}
                onCloseRequest={handleClose}
                onMovePrevRequest={handleMovePrev}
                onMoveNextRequest={handleMoveNext}
              />
            )}
          </GalleryWrapper>
        </GalleryContainer>
      </ContentContainer>
    </Main>
  );
}

const Main = styled.div`
  /* height: 100vh; */
  padding-bottom: 25px;
  width: 100vw;
  max-width: 100%;
  @media (max-width: 1980px) and (max-height: 1080px) {
    display: flex;
    justify-content: center;
  }
`;
const QuoteDiv = styled.div`
  display: flex;
  justify-content: flex-end;
  /* margin-bottom: 40px; */
  margin-right: 23%;
  @media (max-width: 950px) {
    display: none;
  }
  @media (max-width: 1100px) {
    margin-right: 2%;
  }
  @media (min-height: 1081px) {
    margin-right: 31.5%;
  }
`;

const MobileWrapper = styled.span`
  display: none;
  @media (max-width: 750px) {
    display: block;
  }
`;

const DesktopWrapper = styled.span`
  display: block;
  @media (max-width: 750px) {
    display: none;
  }
`;

const TopWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  @media (max-width: 750px) {
    width: 80vw;
    margin-left: 10vw;
  }
`;

const ParagraphMobile = styled.p`
  display: none;
  width: 80vw;
  margin-left: 10vw;
  ${styl.lightDefaultFont};
  color: ${colors.SolidGreyText};
  font-size: 14px;
  @media (max-width: 750px) {
    display: block;
  }
`;

const PictureTextDiv = styled.div`
  width: 60vw;
  max-width: 100vw;
  /* margin-top: 28px; */
  /* margin-bottom: 20px; */
  @media (max-width: 1100px) {
    width: 90vw;
    margin-top: 0;
  }
`;
const ContentContainer = styled.div`
  width: 90vw;
  margin-left: 20vw;
  display: flex;
  flex-direction: column;
  @media (min-width: 1700px) {
    margin-top: 40px;
  }
  @media (max-width: 1700px) {
    margin-left: 20vw;
    margin-top: 40px;
  }
  @media (max-width: 1100px) {
    width: 90vw;
    margin-left: 0;
  }
  @media (max-width: 750px) {
    width: 100%;
    margin-left: 0;
  }
`;

const GalleryContainer = styled.div`
  margin-top: 3%;
  width: 65vw;

  @media (max-width: 1100px) {
    width: 90vw;
  }
  @media (max-width: 750px) {
    width: 80vw;
    margin-left: 10vw;
  }
`;

const GalleryWrapper = styled.div`
  margin-top: 4%;
`;
