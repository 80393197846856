import PropTypes from "prop-types";
import React, { useState } from "react";
import styled from "styled-components";
import Coolbox from "../assets/images/coolboxIcon.png";
import { gql, useQuery } from "@apollo/client";

// Props: title: String (required)

// Example: <HeadingOne title="Example" />
const GetCoolboxInfo = gql`
  query GetCoolboxInfo {
    generalOptions {
      coolbox_shipment {
        coolboxForced
        coolboxPrices {
          price
          weightLimit
        }
      }
    }
  }
`;

const CoolBoxInfo = ({ paddingTop }) => {
  const [hover, setHover] = useState(false);

  const { data: coolboxData } = useQuery(GetCoolboxInfo);

  const startHover = () => {
    setHover(true);
  };

  const endHover = () => {
    setHover(false);
  };
  const prices = coolboxData?.generalOptions?.coolbox_shipment?.coolboxPrices;

  return (
    <Wrapper props_paddingTop={paddingTop}>
      <IconWrapper>
        {/* <FiBox
          color="#808080"
          style={{ height: "18px", width: "18px", zIndex: 1 }}
          onMouseEnter={startHover}
          onClick={() => setHover((prevState) => !prevState)}
          onMouseLeave={endHover}
        /> */}
        <img
          height={"20px"}
          width={"auto"}
          src={Coolbox}
          onMouseEnter={startHover}
          onClick={() => setHover((prevState) => !prevState)}
          onMouseLeave={endHover}
          alt="Coolbox Icon"
        />
      </IconWrapper>
      {hover && prices && (
        <Description
          onClick={() => setHover((prevState) => !prevState)}
          onMouseEnter={startHover}
          onMouseLeave={endHover}
        >
          Zur Sicherstellung der Qualität erfolgt der Versand der
          gekennzeichneten Produkte in Kühlboxen.
          <br /> Kosten je nach Warenkorb <br />
          zwischen EUR {prices[0]?.price?.toFixed(2)?.replace(".", ",")} und EUR{" "}
          {prices[prices?.length - 1]?.price?.toFixed(2)?.replace(".", ",")}.
        </Description>
      )}
    </Wrapper>
  );
};

CoolBoxInfo.propTypes = {
  paddingTop: PropTypes.number,
};

const Wrapper = styled.div`
  text-align: left;
  display: flex;
  margin-left: 10px;
  padding-top: ${(props) =>
    props.props_paddingTop ? props.props_paddingTop + "px" : "unset"};
  display: flex;
  flex-direction: row;
  position: relative;
  /* top: 50%; */
`;

const IconWrapper = styled.div`
  display: flex;
  flex-direction: center;
  height: 100%;
`;

const Description = styled.div`
  position: absolute;
  margin-left: 20px;
  width: 263px;
  background-color: #ffffff;
  color: #808080;
  font-family: "Montserrat";
  font-weight: 300;
  font-size: 14px;
  letter-spacing: 0.35;
  padding: 5px;
  box-shadow: 10px 10px 21px -4px rgba(0, 0, 0, 0.58);
  z-index: 100;
`;

export default CoolBoxInfo;
