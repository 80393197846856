import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import colors from "../constants/colors";
import constants from "../constants/constants";

// Props: title: String (required)

// Example: <HeadingOne title="Example" />

const DiscoverItem = ({ number, heading, description, color, link }) => {
  return (
    <Wrapper onClick={() => window.open(link)}>
      <CircleWrapper>
        <Number $prop_Color={color}>{number}</Number>
      </CircleWrapper>
      <Text>
        <Heading>{heading}</Heading>
        <Description>{description}</Description>
      </Text>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  width: 320px;
  cursor: pointer;
  @media (max-width: 350px) {
    width: 280px;
  }
`;
const CircleWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;
const Text = styled.div`
  width: 270px;
  margin-left: 15px;
`;
const Number = styled.p`
  width: 35px;
  height: 35px;
  background-color: ${(props) =>
    props.$prop_Color ? props.$prop_Color : "#B2982A"};
  color: white;
  font-family: "Montserrat";
  font-weight: 800;
  font-size: 22px;
  text-align: center;
  border-radius: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 2px;
`;

const Heading = styled.p`
  font-family: "Montserrat";
  font-weight: 500;
  font-size: 13px;
  color: #333333;
  margin: 0;
`;

const Description = styled.p`
  font-family: "Montserrat";
  font-weight: 300;
  font-size: 13px;
  color: #333333;
  margin: 0;
`;

export default DiscoverItem;
