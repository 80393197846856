import { selectionSetMatchesResult } from "@apollo/client/cache/inmemory/helpers";
import CSVReader from "react-csv-reader";
import { ThreeDots } from "react-loader-spinner";
import HeadingOne from "../../components/HeadingOne";
import OrderComplete from "../../components/OrderComplete";
import StyledButton from "../../components/StyledButton";
import ThanksScreen from "../../components/ThanksScreen";
import {
  colors,
  constants,
  gql,
  React,
  styled,
  useMutation,
  useState,
} from "../../imports";
const CreateOrder = gql`
  mutation importOrder(
    $initiatorID: Int
    $lineItems: [LineItemInput]
    $showName: String!
    $showPrice: String!
  ) {
    __typename
    importFromCSV(
      input: {
        clientMutationId: "CreateOrder"
        customerId: $initiatorID
        lineItems: $lineItems
        metaData: [
          { key: "showName", value: $showName }
          { key: "showPrice", value: $showPrice }
        ]
      }
    ) {
      order {
        discountTotal
        total
        paymentMethod
        shippingTotal
        subtotal
        totalTax
        orderKey
        orderId
        cartTax
        customerIpAddress
        orderNumber
        billing {
          address1
          city
          country
          email
          firstName
          lastName
          phone
          postcode
          state
        }
        shipping {
          address1
          city
          company
          country
          email
          firstName
          lastName
          postcode
          state
        }
        lineItems {
          nodes {
            variation {
              name
              price
              weight
              variationId
              sku
            }
            metaData {
              key
              value
            }
            quantity
            total
            subtotal
          }
        }
        couponLines {
          nodes {
            discount
          }
        }
      }
    }
  }
`;

export default function ProductOverViewPage() {
  const [saveOrder, { loading: mutationLoading2, error: mutationError2 }] =
    useMutation(CreateOrder);
  const [products, setProducts] = useState([]);

  const [currentPage, setCurrentPage] = useState("Import");
  const [order, setOrder] = useState();
  const [name, setName] = useState();
  const [price, setPrice] = useState();

  const handleCSVInput = (data) => {
    let initiatorID = data[0]?.initiatorid;
    let tmpShowName = data[0]?.name;
    let tmpShowPrice = data[0]?.preis;
    let lineItems = [];
    data.forEach((item) => {
      let article = {
        variationId: item.variationid,
        productId: item.parentid,
        quantity: item.quantity,
        metaData: [
          { key: "Name", value: item.vorname + " " + item.nachname },
          { key: "Email", value: item.email },
        ],
      };
      lineItems.push(article);
    });

    let order = { lineItems, initiatorID };
    setName(tmpShowName);
    setPrice(tmpShowPrice);
    setProducts(order);
  };

  function SaveOrder() {
    let tmpName = "";
    let tmpPrice = "";
    if (name) {
      tmpName = "true";
    }
    if (price) {
      tmpPrice = "true";
    }

    saveOrder({
      variables: {
        initiatorID: products.initiatorID,
        lineItems: products.lineItems,
        showName: tmpName,
        showPrice: tmpPrice,
      },
    })
      .then((response) => {
        setOrder(response.data.importFromCSV.order);
        setCurrentPage("Ordercomplete");
      })
      .catch((error) => {
        alert(error.toString());
      });
  }

  const papaparseOptions = {
    header: true,
    dynamicTyping: true,
    skipEmptyLines: true,
    comments: true,
    transformHeader: (header) => header.toLowerCase().replace(/\W/g, "_"),
  };

  return (
    <Main>
      {currentPage === "Import" ? (
        <ContentContainer>
          <HeadingOne title="Import CSV" />
          <CSVReader
            cssClass="csv-reader-input"
            label="Wähle deine Bestellung(CSV) aus:"
            onFileLoaded={(data) => handleCSVInput(data)}
            parserOptions={papaparseOptions}
          />
          {products.length !== 0 && (
            <StyledButton
              onClick={() => SaveOrder()}
              scale={"ORDER"}
              title={"Weiter"}
              color={colors.YellowBrigthButton}
              fontSize={"20px"}
            />
          )}
        </ContentContainer>
      ) : (
        <ThanksScreen order={order} location={"Import"} />
      )}
      {mutationError2 && <p>Error :(</p>}
      {mutationLoading2 && (
        <ThreeDots height={200} width={100} color={colors.YellowBrigthButton} />
      )}
    </Main>
  );
}

const Main = styled.div`
  padding-bottom: 25px;
  @media (max-width: 1980px) and (max-height: 1080px) {
    display: flex;
    justify-content: center;
  }
`;

const ContentContainer = styled.div`
  /* margin-left: 470px; */
  margin-top: 75px;
  display: flex;
  flex-direction: column;
  width: 1745px;
  @media (max-width: 1980px) and (max-height: 1080px) {
    margin-left: 130px;
    /* max-width: calc(100% - 130px); */
  }
  @media (max-width: 1500px) {
    width: 100%;
    margin: 62px;
  }
  @media (max-width: ${constants.iPhoneLandscapeWidth}px) {
    width: 90%;
    margin: 5%;
  }
`;
