import PropTypes from "prop-types";
import React, { useState } from "react";
import styled from "styled-components";
import colors from "../constants/colors";
import styl from "../styl";
import constants from "./../constants/constants";
import HeadingTwo from "./HeadingTwo";

// Props:
// -
// -
// -
// -
// -

/**
 * @author Samuel Plankel
 * @param {string} color: color of the box (required)
 * @param {any} bgImage background Image of the Box
 * @param {string} title: title of the box
 * @param {string} subTitle: sub title of the box
 * @param {string} hoverText: the text in the box
 * @param {string} href: link to other page
 * @param {string} textHref: link when clicked on the text
 * @param {bool} showHover: toggle if Hover Effect should make the text dissapear or not
 * @param {bool} center: center the text in the box
 * @example <HomepageSquare 
          color={colors.HamptonYellowHomepageSquare} 
          bgImage={cowOnHill}
          href='/philosophy'
          title='philosophie' 
          hoverText='Lorem ipsum Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt'/>
          showHover={true}
 */

export default function HomepageSquare({
  color,
  bgImage,
  title = "",
  subTitle,
  hoverText,
  href,
  textHref,
  showHover = true,
  center = false,
  alwaysHover = false,
  titleColor,
}) {
  const [hover, setHover] = useState(false);
  const [textHover, setTextHover] = useState(false);

  const toggleHover = () => {
    if (showHover) {
      window.innerWidth > constants.nexusPortraitWidth
        ? setHover(!hover)
        : setHover(false);
    } else {
      setHover(false);
    }
  };

  const toggleTextHover = () => {
    window.innerWidth > constants.nexusPortraitWidth
      ? setTextHover(!textHover)
      : setTextHover(false);
  };

  const textStyle = () => {
    const topMargin = subTitle ? "20px" : "30px";
    return hover
      ? {}
      : showHover
      ? { display: "none" }
      : { marginTop: topMargin };
  };

  return (
    <BgImage style={{ backgroundImage: `url(${bgImage})` }}>
      <Main
        style={
          hover || alwaysHover
            ? { backgroundColor: `${color}FF` }
            : { backgroundColor: `rgb(255,255,255,0)` }
        }
        onMouseEnter={toggleHover}
        onMouseLeave={toggleHover}
        href={href}
      >
        <Content style={center ? { textAlign: "center" } : {}}>
          <HeadingTwo
            title={title}
            color={titleColor ? "WHITE" : "BLUE"}
            underline={hover ? "false" : title == "" ? "false" : "true"}
          />
          <SubTitle>{subTitle}</SubTitle>
          <a
            href={textHref}
            style={textHover ? {} : { textDecoration: "none" }}
          >
            <Text
              style={textStyle()}
              onMouseEnter={toggleTextHover}
              onMouseLeave={toggleTextHover}
            >
              {hoverText}
            </Text>
          </a>
          <BottomLine style={hover ? {} : { display: "none" }} />
        </Content>
      </Main>
    </BgImage>
  );
}

HomepageSquare.propTypes = {
  color: PropTypes.string.isRequired,
  bgImage: PropTypes.any,
  title: PropTypes.string,
  SubTitle: PropTypes.string,
  hoverText: PropTypes.string,
  href: PropTypes.string,
  textHref: PropTypes.string,
  showHover: PropTypes.bool,
  center: PropTypes.bool,
  alwaysHover: PropTypes.bool,
  titleColor: PropTypes.string,
};

const BgImage = styled.div`
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
`;

const Main = styled.a`
  height: calc(100vw / 6);
  width: calc(100vw / 6);
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;

  @media (max-width: ${constants.nexusPortraitWidth}px) {
    height: calc(100vw / 3);
    width: calc(100vw / 3);
  }
  @media (max-width: ${constants.iPhonePortraitWidth}px) {
    height: calc(100vw / 2);
    width: calc(100vw / 2);
  }
`;
const Content = styled.div`
  height: 80%;
  width: 80%;
`;
const SubTitle = styled.p`
  ${styl.boldDefaultFont};
  color: ${colors.CongressBlueHeader};
  font-weight: 601;
  text-transform: uppercase;
  margin: 0;
  font-size: 14px;
  @media (max-width: 1220px) {
    font-size: 10px;
  }
  @media (max-width: 900px) {
    font-size: 8px;
  }
`;
const Text = styled.p`
  ${styl.lightDefaultFont};
  font-size: 14px;
  margin: 0;
  padding: 0;
  color: ${colors.CongressBlueHeader};
  @media (max-width: 1500px) {
    margin-top: 10px !important;
  }
  @media (max-width: 1220px) {
    font-size: 10px;
    margin-top: 5px !important;
  }
  @media (max-width: 900px) {
    font-size: 8px;
    margin-top: 0px !important;
  }
`;
const BottomLine = styled.div`
  width: 65px;
  margin-top: 5px;
  border-bottom: 2px solid ${colors.CongressBlueHeader};

  @media (max-width: ${constants.nexusLandscapeWidth}px) {
    width: 48px;
  }
  @media (max-width: ${constants.nexusPortraitWidth}px) {
    width: 35px;
  }
`;
