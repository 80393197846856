import React, { useContext, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";
import styled from "styled-components";
import colors from "../constants/colors";
import constants from "../constants/constants";
import { GetUserContext } from "../localStorage/Store";
import HeadingOne from "./HeadingOne";
import Footer from "./Footer";

// Hook
function useWindowSize() {
  const [windowSize, setWindowSize] = useState({
    width: 1000,
    height: undefined,
  });

  useEffect(() => {
    function handleResize() {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    }
    window.addEventListener("resize", handleResize);
    handleResize();
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return windowSize;
}

/**
 * @author Samuel Plankel
 * @param children Components wrapped inside this component
 * @param {function} activeView the function which is called when the user navigates to a new View
 * @param {string} currentPage name of the currently active view
 * @param {boolean} clickable a boolean that switches if the Navigation is clickable or not
 * @param {boolean} noHeader a boolean to turn of the display of the navigation
 * @param {number} minheight4k the minheight of the container when the display is bigger than fullHD, default is 400
 * @param {object} navigation an object which contains all view/page names wich has to be displayed
 * @example const navigation = [{actualName: ["Benutzerdaten", "Bestellung"]}, {codeName: ["RegisterSuborderer", "AddProduct"]}]
 * @example <OrderContainer
      activeView={(pageName) => handleView(pageName)}
      currentPage={currentPage}
      navigation={navigation}
      clickable={false}
    ></OrderContainer>
 */

const OrderContainer = ({
  children,
  activeView,
  currentPage,
  navigation,
  clickable,
  noHeader,
  minheight4k = 400,
}) => {
  const [user, setUser] = useContext(GetUserContext);
  const [activeIndex, setActiveIndex] = useState(
    navigation[1].codeName.findIndex((element) => element === currentPage)
  );
  const size = useWindowSize();
  let mobilePageName =
    navigation[0].actualName[activeIndex] === undefined
      ? "Bestellen"
      : navigation[0].actualName[activeIndex];

  const handleOnClick = (index, tmp, type) => {
    if (clickable) {
      if (
        currentPage == "UserData" &&
        user.billing?.city &&
        user.billing?.postcode &&
        user.billing?.address1 &&
        user.billing?.country &&
        user.shipping?.city &&
        user.shipping?.postcode &&
        user.shipping?.address1 &&
        user.shipping?.country
      ) {
        checkBackForward(index, type);
      } else if (currentPage != "UserData") {
        checkBackForward(index, type);
      } else {
        alert("Noch nicht alle Daten vorhanden");
      }
    }
  };

  const checkBackForward = (index, type) => {
    if (type !== undefined) {
      if (type === "back") {
        activeView(navigation[1].codeName[activeIndex - 1]);
      }
      if (type === "forward") {
        activeView(navigation[1].codeName[activeIndex + 1]);
      }
    } else {
      activeView(navigation[1].codeName[index]);
    }
  };

  const handleUnderline = (index) => {
    if (
      (currentPage === "OrderOverview" || currentPage === "OrderComplete") &&
      navigation[1].codeName[index] === "AddProduct"
    )
      return "true";
    else {
      if (currentPage === navigation[1].codeName[index]) {
        return "true";
      } else {
        return "false";
      }
    }
  };

  useEffect(() => {
    setActiveIndex(
      navigation[1].codeName.findIndex((element) => element === currentPage)
    );
    return () => {};
  }, [activeView]);

  return (
    <Main>
      <Wrapper>
        {!noHeader && (
          <WrapperNavigation>
            <ContentNavigation>
              {size.width > constants.nexusPortraitWidth ? (
                navigation[0].actualName.map((tmp, index) => (
                  <WrapperTitle
                    onClick={() => handleOnClick(index)}
                    clickable={clickable}
                    key={index}
                  >
                    <HeadingOne
                      title={tmp}
                      underline={handleUnderline(index)}
                      fontSize={size.width > 950 ? 40 : ""}
                    />
                  </WrapperTitle>
                ))
              ) : (
                <WrapperNavigationMobile>
                  <ArrowLeftAndTitle>
                    <div
                      style={{
                        height: "35px",
                        width: "36px",
                        marginBottom: "16px",
                      }}
                    >
                      {activeIndex !== 0 && currentPage !== "OrderComplete" && (
                        <IoIosArrowBack
                          style={{
                            color: colors.CongressBlueHeader,
                            cursor: "pointer",
                            height: "100%",
                            width: "100%",
                          }}
                          onClick={() => {
                            handleOnClick("", "", "back");
                          }}
                        />
                      )}
                    </div>
                    <HeadingOne
                      title={mobilePageName}
                      // underline={currentPage === navigation[1].codeName[index] ? "true" : "false"}
                      underline={true}
                    />
                  </ArrowLeftAndTitle>
                  {(currentPage === "UserData" || currentPage === "Invite") && (
                    <IoIosArrowForward
                      style={{
                        color: colors.CongressBlueHeader,
                        cursor: "pointer",
                        height: "35px",
                        minWidth: "36px",
                        marginBottom: "14px",
                      }}
                      onClick={() => {
                        handleOnClick("", "", "forward");
                      }}
                    />
                  )}
                </WrapperNavigationMobile>
              )}
            </ContentNavigation>
          </WrapperNavigation>
        )}
        <WrapperChildren minheight4k={minheight4k}>
          <WrapperChildrenSpacer>{children}</WrapperChildrenSpacer>
        </WrapperChildren>
      </Wrapper>
      <Footer fontColor={"#000000"} />
    </Main>
  );
};

OrderContainer.propTypes = {
  children: PropTypes.any,
  activeView: PropTypes.func.isRequired,
  currentPage: PropTypes.string.isRequired,
  navigation: PropTypes.array.isRequired,
  clickable: PropTypes.bool,
  noHeader: PropTypes.bool,
  minheight4k: PropTypes.number,
};

const Divider = styled.div`
  display: none;
  @media (max-width: 374px) {
    display: block;
    width: 100px;
    height: 50px;
    background-color: red;
  }
`;

const Main = styled.div`
  flex: 1;
  display: flex;
  max-width: 1620px;
  align-items: center;
  flex-direction: column;
  height: 100vh;
`;
const Wrapper = styled.div`
  width: 92vw;
  max-width: 1440px;
  margin-top: 5vh;
  @media (max-width: ${constants.nexusPortraitWidth}px) {
    width: 100%;
  }
  @media (max-width: 374px) {
    margin-bottom: 30px;
  }
`;
const WrapperNavigation = styled.div`
  height: 16vh;
  max-height: 75px;
  min-height: 58px;
  @media (max-width: ${constants.nexusPortraitWidth}px) {
    display: flex;
    justify-content: center;
  }
`;
const ContentNavigation = styled.div`
  font-family: "Sorts Mill Goudy", serif;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  @media (max-width: ${constants.nexusPortraitWidth}px) {
    justify-content: center;
    width: 92%;
  }
`;
const WrapperNavigationMobile = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  @media (max-width: 800px) {
    margin-bottom: 40px;
  }
`;
const ArrowLeftAndTitle = styled.div`
  display: flex;
  align-items: center;
`;
const WrapperChildren = styled.div`
  display: flex;
  justify-content: center;
  background-color: ${colors.SolidWhiteBackground};
  min-height: 400px;
  /* margin-bottom: 40px; */
  @media (min-height: 1000px) {
    min-height: ${(props) => props.minheight4k}px;
  }
`;
const WrapperChildrenSpacer = styled.div`
  width: 92%;
  margin-top: 6vh;
  margin-bottom: 4vh;
  @media (max-width: ${constants.nexusPortraitWidth}px) {
    display: flex;
    justify-content: center;
  }
`;
const WrapperTitle = styled.div`
  margin-right: 2vw;
  cursor: ${(props) => (props.clickable ? "pointer" : "default")};
`;

export default OrderContainer;
