import React, { useEffect, useState } from "react";
import { Gallery } from "react-grid-gallery";
import styled from "styled-components";
import MelchiorCheese1 from "../../assets/images/melchior_cheese1.jpg";
import MelchiorCheese2 from "../../assets/images/melchior_cheese2.jpg";
import MelchiorSmall from "../../assets/images/melchior_sw.jpg";
import HeadingOne from "../../components/HeadingOne";
import HeadingTwo from "../../components/HeadingTwo";
import PictureText from "../../components/PictureText";
import Quote from "../../components/Quote";
import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css";

// Import Images
const IMAGES = [
  {
    src: "https://api.gutgereift.at/app/uploads/2020/10/vorsaess1.jpg",
    thumbnail: "https://api.gutgereift.at/app/uploads/2020/10/vorsaess1.jpg",
  },
  {
    src: "https://api.gutgereift.at/app/uploads/2020/10/vorsaess6.jpg",
    thumbnail: "https://api.gutgereift.at/app/uploads/2020/10/vorsaess6.jpg",
  },
  {
    src: "https://api.gutgereift.at/app/uploads/2020/10/vorsaess9.jpg",
    thumbnail: "https://api.gutgereift.at/app/uploads/2020/10/vorsaess9.jpg",
  },
  {
    src: "https://api.gutgereift.at/app/uploads/2020/10/vorsaess3.jpg",
    thumbnail: "https://api.gutgereift.at/app/uploads/2020/10/vorsaess3.jpg",
  },
  {
    src: "https://api.gutgereift.at/app/uploads/2020/10/vorsaess4.jpg",
    thumbnail: "https://api.gutgereift.at/app/uploads/2020/10/vorsaess4.jpg",
  },
  {
    src: "https://api.gutgereift.at/app/uploads/2020/10/vorsaess2.jpg",
    thumbnail: "https://api.gutgereift.at/app/uploads/2020/10/vorsaess2.jpg",
  },
  {
    src: "https://api.gutgereift.at/app/uploads/2020/10/vorsaess15.jpg",
    thumbnail: "https://api.gutgereift.at/app/uploads/2020/10/vorsaess15.jpg",
  },
  {
    src: "https://api.gutgereift.at/app/uploads/2020/10/vorsaess5.jpg",
    thumbnail: "https://api.gutgereift.at/app/uploads/2020/10/vorsaess5.jpg",
  },
  {
    src: "https://api.gutgereift.at/app/uploads/2020/10/vorsaess12.jpg",
    thumbnail: "https://api.gutgereift.at/app/uploads/2020/10/vorsaess12.jpg",
  },
  {
    src: "https://api.gutgereift.at/app/uploads/2020/10/vorsaess16-1.jpg",
    thumbnail: "https://api.gutgereift.at/app/uploads/2020/10/vorsaess16-1.jpg",
  },
  {
    src: "https://api.gutgereift.at/app/uploads/2020/10/vorsaess7.jpg",
    thumbnail: "https://api.gutgereift.at/app/uploads/2020/10/vorsaess7.jpg",
  },
  {
    src: "https://api.gutgereift.at/app/uploads/2020/10/vorsaess13.jpg",
    thumbnail: "https://api.gutgereift.at/app/uploads/2020/10/vorsaess13.jpg",
  },
];
export default function AttitudePage() {
  const [index, setIndex] = useState(-1);

  const currentImage = IMAGES[index];
  const nextIndex = (index + 1) % IMAGES.length;
  const nextImage = IMAGES[nextIndex] || currentImage;
  const prevIndex = (index + IMAGES.length - 1) % IMAGES.length;
  const prevImage = IMAGES[prevIndex] || currentImage;

  const handleClick = (index, item) => setIndex(index);
  const handleClose = () => setIndex(-1);
  const handleMovePrev = () => setIndex(prevIndex);
  const handleMoveNext = () => setIndex(nextIndex);

  useEffect(() => {
    document.title = "Melchior";
  }, []);

  return (
    <Main>
      <ContentContainer>
        <TopWrapper>
          <WrapperWithPicture>
            <HeadingOne title="Melchior" />
            <div style={{ height: "15px" }} />
            <SmallImg src={MelchiorSmall} />
          </WrapperWithPicture>
          <QuoteDiv>
            <Quote
              mainText={["Nëam dôrwiel, gîb", <br />, "obacht, häb Sôarg!"]}
              description={[
                "Sich Zeit nehmen und die",
                <br />,
                "Dinge im Auge behalten",
              ]}
            />
          </QuoteDiv>
        </TopWrapper>
        <PictureTextDiv>
          <DesktopWrapper>
            <PictureText
              text={
                "Meine Profession ist das Organisieren auf allen Ebenen von gut gereift. Ich kenne die Bauern und Sennen persönlich und bin in alle Schritte – vom Einkauf bis zum Versand - eingebunden. Den Ausgleich dazu hole ich mir im Käselager der stillgelegten Sennerei Hof-Meßmerreuthe. Dort pflege und bürste ich die Laibe händisch, zweimal wöchentlich. Schnelligkeit ist hier kein Erfolgsrezept, sondern nur der sorgsame Umgang mit dem Käse. Das ist für mich ein ständiger Lernprozess. "
              }
              picture={MelchiorCheese2}
              format={"row"}
            ></PictureText>
          </DesktopWrapper>

          <PictureText
            text={[
              "Im Frühjahr 2020 habe ich mit meiner Frau Hilda und unserer kleinen Tochter die Vorsäßzeit auf der Alpe Untere Falz verbracht: Ganz bewusst, um neue Erfahrungen zu sammeln. 30 Kühe, 10 Kälber, 80 Rinder und 35 Schweine galt es zu versorgen. Mit fünf Geschwistern aufgewachsen auf einer Landwirtschaft, habe ich erneut erfahren, wieviel die Landwirte im Jahreszeitenkreislauf leisten. Dieser wahrgenommenen und gelebten Verantwortung gegenüber Natur und Tier zolle ich großen Respekt. In dieser Zeit habe ich zudem gelernt, wie wenig es eigentlich braucht, um zufrieden zu sein.",
              <br />,
              "Über ein Jahrzehnt war ich als Kassier der Sennereigenossenschaft Mühle-Hub tätig. Dadurch sind mir die Herausforderungen der klein strukturierten Wirtschaftsweisen vertraut. Durch das Schaffen neuer Vertriebsformen möchte ich ein sichtbares Zeichen setzen, um altbewährte Strukturen zu erhalten.",
            ]}
            picture={MelchiorCheese1}
            format={"row-reverse"}
          ></PictureText>
        </PictureTextDiv>
        <GalleryContainer>
          <HeadingTwo title="Vorsäßzeit" color="DEFAULT" />
          <GalleryWrapper>
            <Gallery
              images={IMAGES}
              enableImageSelection={false}
              onClick={handleClick}
            />
            {!!currentImage && (
              /* @ts-ignore */
              <Lightbox
                mainSrc={currentImage.src}
                mainSrcThumbnail={currentImage.thumbnail}
                nextSrc={nextImage.src}
                nextSrcThumbnail={nextImage.thumbnail}
                prevSrc={prevImage.src}
                prevSrcThumbnail={prevImage.thumbnail}
                onCloseRequest={handleClose}
                onMovePrevRequest={handleMovePrev}
                onMoveNextRequest={handleMoveNext}
              />
            )}
          </GalleryWrapper>
        </GalleryContainer>
      </ContentContainer>
    </Main>
  );
}

const Main = styled.div`
  /* height: 100vh; */
  padding-bottom: 25px;
  width: 100vw;
  max-width: 100%;
  max-width: 960px;
  margin-left: 20vw;
  @media (max-width: 1900px) {
    margin-left: 10vw;
  }
  @media (max-width: 1200px) {
    margin-left: 0;
  }
  @media (max-width: 1980px) and (max-height: 1080px) {
    display: flex;
    justify-content: center;
  }
`;
const QuoteDiv = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-bottom: 40px;
  max-width: 50vw;
  @media (max-width: 950px) {
    display: none;
  }
`;

const DesktopWrapper = styled.span`
  display: block;
  /* @media (max-width: 750px) {
    display: none;
  } */
`;

const TopWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  @media (max-width: 1100px) {
    width: 80vw;
    margin-left: 10vw;
  }
`;

const WrapperWithPicture = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const SmallImg = styled.img`
  width: 150px;
  height: 150px;
  margin-bottom: 30px;

  @media (max-width: 770px) {
    margin-top: 15px;
  }
`;

const PictureTextDiv = styled.div`
  width: 60vw;
  max-width: 100vw;
  margin-top: 10px;
  margin-bottom: 20px;
  max-width: 960px;
  @media (max-width: 1600px) {
    width: 65vw;
    margin-top: 0;
  }
  @media (max-width: 1500px) {
    width: 75vw;
    margin-top: 0;
  }
  @media (max-width: 1300px) {
    width: 90vw;
    margin-top: 0;
  }
  @media (max-width: 1100px) {
    /* width: 95vw; */
    margin-top: 0;
    width: 100vw;
    margin-left: 0;
  }
`;
const ContentContainer = styled.div`
  width: 90vw;
  margin-left: 20vw;
  display: flex;
  flex-direction: column;
  max-width: 960px;
  margin-top: 30px;
  /* @media (max-width: 1980px) and (max-height: 1080px) {
    margin-top: 75px;
  }
  @media (max-width: 1700px) {
    margin-left: 20vw;
  }*/
  @media (max-width: 1300px) {
    width: 90vw;
    margin-left: 5vw;
  }
  /* @media (max-width: 1080px) {
    width: 95vw;
    margin-left: 2.5vw;
  } */
  @media (max-width: 1100px) {
    width: 100%;
    margin-left: 0;
  }
`;

const GalleryContainer = styled.div`
  margin-top: 3%;
  width: 65vw;
  max-width: 960px;
  @media (max-width: 1300px) {
    width: 90vw;
    margin-left: 5vw;
    /* margin-left: 5vw; */
  }
  /* @media (max-width: 1100px) {
    width: 90vw;
  }
  @media (max-width: 1100px) {
    width: 80vw;
    margin-left: 10vw;
  } */
`;

const GalleryWrapper = styled.div`
  margin-top: 4%;
`;
