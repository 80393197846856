// Created by:      Marcel Simma
// Copyright:       © 2020 Zewas OG
// Creation date:   31.07.2020
// Last edited:     02.08.2020

import React, { useContext, useEffect, useState } from "react";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import Cookies from "universal-cookie";
import DefaultWindow from "./components/DefaultWindow";
import OrderOverviewWithId from "./components/OrderOverviewWithId";
import colors from "./constants/colors";
import { getToken } from "./constants/util";
import { GetFallbackURL, GetUserContext } from "./localStorage/Store";
import AttitudePage from "./pages/attitudePage/AttitudePage";
import Community from "./pages/community/Community";
import ContactPage from "./pages/contactPage/ContactPage";
import DataInputBestellinitiator from "./pages/dataInputBestellinitiator/DataInputBestellinitiator";
import DataInputSubbesteller from "./pages/dataInputSubbesteller/DataInputSubbesteller";
import Error404 from "./pages/error/Error404";
import Homepage from "./pages/homepage/Homepage";
import ImportOrder from "./pages/importOrder/ImportSubOrder";
import InvitePage from "./pages/invite/InvitePage";
import Landingpage from "./pages/landingpage/Landingpage";
import LandingpageSubbesteller from "./pages/landingpageSubbesteller/LandingpageSubbesteller";
import AGB from "./pages/legal/AGB";
import Datenschutz from "./pages/legal/Datenschutz";
import Impressum from "./pages/legal/impressum";
import OverviewPage from "./pages/login/OverviewPage";
import RegisterPage from "./pages/login/RegisterPage";
import MultipleBuyerPage from "./pages/multipleBuyerPage/MultipleBuyerPage";
import OrderHistory from "./pages/orderHistory/OrderHistory";
import PhilosophiePage from "./pages/philosophiePage/PhilosophiePage";
import ProductDetailPage from "./pages/productDetailPage/ProductDetailPage";
import ProductOverViewPage from "./pages/productOverviewPage/ProductOverviewPage";
import Profile from "./pages/profilePage/ProfilePage";
import ResetPasswordPage from "./pages/resetPassword/ResetPasswordPage";
import SingleBuyerPage from "./pages/singleBuyerPage/SingleBuyerPage";
import StartOrderPage from "./pages/startOrderPage/StartOrderPage";
import Hilda from "./pages/whoWeAre/Hilda";
import Melchior from "./pages/whoWeAre/Melchior";
import Partner from "./pages/whoWeAre/Partner";
import WhoWeAre from "./pages/whoWeAre/WhoWeAre";
import DiscoverPage from "./pages/discover/DiscoverPage";

const Router = () => {
  const [user, setUser] = useContext(GetUserContext);
  const cookies = new Cookies();
  const [url, setURL] = useContext(GetFallbackURL);
  const [enable, setEnable] = useState(false);

  useEffect(() => {
    (async () => {
      if (
        !window.location.href.includes("login") &&
        !window.location.href.includes("resetpw") &&
        !window.location.href.includes("home")
      ) {
        await setURL(window.location.href);
      } else {
        await setURL("./bestellen");
      }
      setEnable(true);
    })();
  }, []);

  useEffect(() => {
    (async () => {
      const token = await getToken();
      if (!token) {
        setUser(null);
      }
    })();
  }, [setUser]);

  return (
    enable && (
      <BrowserRouter>
        <Routes>
          <Route
            path="/"
            element={
              <DefaultWindow
                showHeader={false}
                footerFontColor={colors.SolidWhiteBackground}
                background={colors.Cararra}
              >
                <Landingpage />
              </DefaultWindow>
            }
          />

          <Route
            path="/home"
            element={
              <DefaultWindow
                showHeader={false}
                footerFontColor={colors.SolidWhiteBackground}
                background={colors.Cararra}
              >
                <Homepage />
              </DefaultWindow>
            }
          />

          <Route
            path="/werwirsind"
            element={
              <DefaultWindow
                showHeader={true}
                footerFontColor={colors.SolidBlackText}
                background={colors.Cararra}
              >
                <WhoWeAre />
              </DefaultWindow>
            }
          />
          <Route
            path="/bestellen"
            element={
              <DefaultWindow showHeader={true}>
                <StartOrderPage />
              </DefaultWindow>
            }
          />

          <Route
            path="/philosophie"
            element={
              <DefaultWindow showHeader={true} background={colors.Cararra}>
                <PhilosophiePage />
              </DefaultWindow>
            }
          />
          <Route
            path="/entdecken"
            element={
              <DefaultWindow
                showHeader={true}
                footerFontColor={colors.SolidBlackText}
                background={colors.Cararra}
              >
                <DiscoverPage />
              </DefaultWindow>
            }
          />

          <Route
            path="/haltung"
            element={
              <DefaultWindow
                showHeader={true}
                footerFontColor={colors.SolidBlackText}
                background={colors.Cararra}
              >
                <AttitudePage />
              </DefaultWindow>
            }
          />
          <Route
            path="/produkte"
            element={
              <DefaultWindow
                showHeader={true}
                background={colors.Cararra}
                footerFontColor={colors.SolidBlackText}
              >
                <ProductOverViewPage />
              </DefaultWindow>
            }
          />

          <Route
            path="/produktDetail"
            element={
              <DefaultWindow showHeader={true} background={colors.Cararra}>
                <ProductDetailPage />
              </DefaultWindow>
            }
          />
          <Route
            path="/kontakt"
            element={
              <DefaultWindow
                showHeader={false}
                footerFontColor={colors.SolidWhiteBackground}
                background={colors.Cararra}
              >
                <ContactPage />
              </DefaultWindow>
            }
          />

          <Route
            path="/melchior"
            element={
              <DefaultWindow
                showHeader={true}
                footerFontColor={colors.SolidBlackText}
                background={colors.Cararra}
              >
                <Melchior />
              </DefaultWindow>
            }
          />

          <Route
            path="/hilda"
            element={
              <DefaultWindow
                showHeader={true}
                footerFontColor={colors.SolidBlackText}
                background={colors.Cararra}
              >
                <Hilda />
              </DefaultWindow>
            }
          />

          <Route
            path="/partner"
            element={
              <DefaultWindow
                showHeader={true}
                footerFontColor={colors.SolidBlackText}
                background={colors.Cararra}
              >
                <Partner />
              </DefaultWindow>
            }
          />

          <Route
            path="/Community"
            element={
              <DefaultWindow showHeader={true} background={colors.Cararra}>
                <Community />
              </DefaultWindow>
            }
          />

          <Route
            path="/impressum"
            element={
              <DefaultWindow showHeader={true} background={colors.Cararra}>
                <Impressum />
              </DefaultWindow>
            }
          />

          <Route
            path="/datenschutz"
            element={
              <DefaultWindow showHeader={true} background={colors.Cararra}>
                <Datenschutz />
              </DefaultWindow>
            }
          />

          <Route
            path="/agb"
            element={
              <DefaultWindow showHeader={true} background={colors.Cararra}>
                <AGB />
              </DefaultWindow>
            }
          />

          <Route
            path="/login"
            element={
              <DefaultWindow showHeader={true}>
                <OverviewPage />
              </DefaultWindow>
            }
          />
          <Route
            path="/register"
            element={
              <DefaultWindow showHeader={true}>
                {user ? <Navigate to={"./home"} replace /> : <RegisterPage />}
              </DefaultWindow>
            }
          />

          <Route
            path="/landingpageSubbesteller"
            element={
              <DefaultWindow
                showHeader={false}
                footerFontColor={colors.SolidWhiteBackground}
                background={colors.Cararra}
              >
                <LandingpageSubbesteller />
              </DefaultWindow>
            }
          />

          <Route
            path="/resetpw"
            element={
              <DefaultWindow
                showHeader={true}
                footerFontColor={colors.SolidWhiteBackground}
              >
                <ResetPasswordPage />
              </DefaultWindow>
            }
          />
          <Route
            path="/dateneingabeSubbesteller"
            element={
              <DefaultWindow
                showHeader={true}
                footerFontColor={colors.SolidWhiteBackground}
                showFooter={false}
              >
                <DataInputSubbesteller />
              </DefaultWindow>
            }
          />
          <Route
            path="/dateneingabeBestellinitiator"
            element={
              <DefaultWindow
                showHeader={true}
                footerFontColor={colors.SolidWhiteBackground}
                showFooter={false}
                background={colors.Cararra}
              >
                <DataInputBestellinitiator />
              </DefaultWindow>
            }
          />

          <Route
            path={"/profile"}
            element={
              user ? (
                <DefaultWindow
                  showHeader={true}
                  footerFontColor={colors.SolidWhiteBackground}
                  background={colors.Cararra}
                  showFooter={false}
                >
                  <Profile />
                </DefaultWindow>
              ) : (
                <Navigate to={"/login"} replace />
              )
            }
          />

          <Route
            path={"/einladen"}
            element={
              user ? (
                <DefaultWindow
                  showHeader={true}
                  footerFontColor={colors.SolidGreyText}
                  background={colors.Cararra}
                  showFooter={false}
                >
                  <InvitePage />
                </DefaultWindow>
              ) : (
                <Navigate to={"/login"} replace />
              )
            }
          />

          <Route
            path={"/einzeln"}
            element={
              user ? (
                <DefaultWindow
                  showHeader={true}
                  footerFontColor={colors.SolidGreyText}
                  showFooter={false}
                >
                  <SingleBuyerPage />
                </DefaultWindow>
              ) : (
                <Navigate to={"/login"} replace />
              )
            }
          />

          <Route
            path={"/gemeinsam"}
            element={
              user ? (
                <DefaultWindow
                  showHeader={true}
                  footerFontColor={colors.SolidGreyText}
                  showFooter={false}
                >
                  <MultipleBuyerPage />
                </DefaultWindow>
              ) : (
                <Navigate to={"/login"} replace />
              )
            }
          />
          <Route
            path={"/bestellhistorie"}
            element={
              user ? (
                <DefaultWindow
                  showHeader={true}
                  footerFontColor={colors.SolidGreyText}
                  showFooter={false}
                >
                  <OrderHistory />
                </DefaultWindow>
              ) : (
                <Navigate to={"/login"} replace />
              )
            }
          />
          <Route
            path={"/bestellhistorie/detail"}
            element={
              user ? (
                <DefaultWindow
                  showHeader={true}
                  footerFontColor={colors.SolidGreyText}
                  background={colors.Cararra}
                  showFooter={false}
                >
                  <OrderOverviewWithId />
                </DefaultWindow>
              ) : (
                <Navigate to={"/login"} replace />
              )
            }
          />
          <Route
            path={"/import"}
            element={
              user ? (
                <DefaultWindow
                  showHeader={true}
                  footerFontColor={colors.SolidWhiteBackground}
                  background={colors.Cararra}
                >
                  <ImportOrder />
                </DefaultWindow>
              ) : (
                <Navigate to={"/login"} replace />
              )
            }
          />

          <Route
            path="*"
            element={
              <DefaultWindow
                showHeader={true}
                footerFontColor={colors.SolidBlackText}
                background={colors.Cararra}
              >
                <Error404 />
              </DefaultWindow>
            }
          />
        </Routes>
      </BrowserRouter>
    )
  );
};

export default Router;
