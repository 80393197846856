import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import colors from "../constants/colors";
import constants from "../constants/constants";

// Props: title: String (required)

// Example: <HeadingOne title="Example" />

const HeadingOne = ({
  title,
  color = "DEFAULT",
  underline = "true",
  fontSize = 47,
}) => {
  return (
    <Wrapper>
      <H1 $prop_Color={color} $prop_FontSize={fontSize}>
        {title}
      </H1>
      <BottomLine $prop_Color={color} $prop_Underline={underline} />
    </Wrapper>
  );
};

HeadingOne.propTypes = {
  title: PropTypes.string.isRequired,
  color: PropTypes.oneOf(["DEFAULT", "WHITE"]),
  underline: PropTypes.oneOf(["true", "false"]),
  fontSize: PropTypes.number,
};

const Wrapper = styled.div`
  text-align: left;
  display: inline-block;
`;

const H1 = styled.h1`
  color: ${(props) =>
    props.$prop_Color === "WHITE"
      ? colors.SolidWhiteBackground
      : colors.CongressBlueHeader};
  font-size: ${(props) => props.$prop_FontSize}px;
  font-family: "Sorts Mill Goudy", serif;
  font-weight: 10;
  line-height: calc(${(props) => props.$prop_FontSize}px + 9px);
  margin: 0;
  margin-bottom: 5px;

  @media (max-width: ${constants.nexusPortraitWidth}px) {
    font-size: 37px;
    margin: 0;
    line-height: unset;
  }

  @media (max-width: 380px) {
    font-size: 30px;
  }
`;

const BottomLine = styled.div`
  display: ${(props) => (props.$prop_Underline === "false" ? "none" : "block")};
  width: 90px;
  border-bottom: 2px solid
    ${(props) =>
      props.$prop_Color === "WHITE"
        ? colors.SolidWhiteBackground
        : colors.OldgoldYellowLogo};
`;

export default HeadingOne;
