import { gql, useMutation, useQuery } from "@apollo/client";
import queryString from "query-string";
import React, { useState } from "react";
import { ThreeDots } from "react-loader-spinner";
import styled from "styled-components";
import Brand from "../assets/images/Brand.jpg";
import colors from "../constants/colors";
import Error404 from "../pages/error/Error404";
import Accordion from "./Accordion/Accordion";
import Alert from "./Alert";
import CircleWithText from "./CircleWithText";
import OrderComplete from "./OrderComplete";
import OrderContainer from "./OrderContainer";
import StyledButton from "./StyledButton";
import { useNavigate } from "react-router-dom";

const GetOrderById = gql`
  query getOrderById($orderId: Int) {
    __typename
    order(orderId: $orderId) {
      total
      totalWeight
      paymentMethod
      shippingTotal
      shippingLines {
        nodes {
          orderId
          total
          totalTax
          methodTitle
        }
      }
      subtotal
      totalTax
      orderKey
      orderId
      date
      cartTax
      customerIpAddress
      orderNumber
      suborderersInformed
      invoiceUrl
      customer {
        firstName
        lastName
        customerId
      }
      orderArrived {
        orderarrived
      }
      billing {
        address1
        city
        country
        email
        firstName
        lastName
        phone
        postcode
        state
      }
      shipping {
        address1
        city
        company
        country
        email
        firstName
        lastName
        postcode
        state
      }
      lineItems {
        nodes {
          variation {
            name
            price
            regularPrice
            weight
            variationId
            sku
            parent {
              name
            }
          }
          metaData {
            key
            value
          }
          quantity
          total
          subtotal
          productId

          totalTax
        }
      }
      lists {
        lists {
          name
          total
          orderers {
            name
            paid
            paidRowId
            products {
              amount
              total
              product {
                name
                price
                weight
                variationId
                parent {
                  name
                  coolbox {
                    coolboxActivated
                    coolboxWeight
                  }
                }
              }
            }
            total
          }
          total
        }
        total
      }
    }
  }
`;

const SetOrderArrived = gql`
  mutation dateOrderArrived(
    $clientMutationId: String!
    $orderId: Int!
    $date: String!
  ) {
    __typename
    dateOrderArrived(
      input: {
        clientMutationId: $clientMutationId
        orderId: $orderId
        date: $date
      }
    ) {
      date
    }
  }
`;

const InformSuborderersMutation = gql`
  mutation informSuborderers($clientMutationId: String!, $orderId: Int!) {
    __typename
    informSuborderers(
      input: { clientMutationId: $clientMutationId, orderId: $orderId }
    ) {
      success
    }
  }
`;

/**
 * @author Sandro Mennel, Julius Lässer
 * @param {string} orderId the id of the order which should be shown
 * @param {function} activeView the function which is called when the back or complete Order Button has been fired
 * @example <OrderOverviewWithId activeView={() => handleView()} orderId={orderId} />
 */

const OrderOverview = ({ setActiveView, onOrderChanged }) => {
  let { orderId } = queryString.parse(window.location.search);
  orderId = parseInt(orderId);

  const {
    loading,
    error,
    data: content,
  } = useQuery(GetOrderById, {
    fetchPolicy: "network-only",
    variables: { orderId },
    onCompleted: (OrderItems) => {
      if (OrderItems?.order) {
        setSelected([]);
        let Array = [];
        let order = OrderItems.order;

        onOrderChanged(order);
        let userTemp = {
          shipping: {
            firstName: order.shipping.firstName,
            lastName: order.shipping.lastName,
            address1: order.shipping.address1,
            city: order.shipping.city,
            country: order.shipping.country,
            postcode: order.shipping.postcode,
          },
          billing: {
            address1: order.billing.address1,
            city: order.billing.city,
            country: order.billing.country,
            email: order.billing.email,
            firstName: order.billing.firstName,
            lastName: order.billing.lastName,
            phone: order.billing.phone,
            postcode: order.billing.postcode,
          },
        };

        order.lineItems.nodes.map((orderItem) => {
          if (
            Array.some((e) => e.variationId === orderItem.variation.variationId)
          ) {
            let i = Array.findIndex(
              (x) => x.variationId === orderItem.variation.variationId
            );
            Array[i].quantity += orderItem.quantity;
            Array[i].totalProductPrice = (
              Number(Array[i].totalProductPrice) +
              Number(orderItem.total) +
              Number(orderItem.totalTax)
            ).toFixed(2);
          } else {
            Array.push(fillList(orderItem));
          }
        });
        setSelected(Array);
        setUser(userTemp);
        const singleBuyerTmp =
          order.lists.lists.length === 1 &&
          order.lists.lists[0]?.name === "initiator"
            ? true
            : false;
        setSingleBuyer(singleBuyerTmp);
      }
    },
  });

  const [
    dateOrderArrived,
    { loading: orderArrivedLoading, error: oderArrivedError },
  ] = useMutation(SetOrderArrived, {
    update: (cache, mutationResult) => {
      cache.writeQuery({
        query: GetOrderById,
        data: {
          order: {
            ...content.order,
            orderArrived: { orderarrived: mutationResult.date },
          },
        },
      });
    },
  });
  const [
    informSuborderers,
    { loading: informSuborderesLoading, error: informSuborderersError },
  ] = useMutation(InformSuborderersMutation, {
    update: (cache, mutationResult) => {
      cache.writeQuery({
        query: GetOrderById,
        data: {
          order: {
            ...content.order,
            suborderersInformed: mutationResult.success,
          },
        },
      });
    },
  });
  const [user, setUser] = useState();
  const [selected, setSelected] = useState([]);
  const [showAlert1, setShowAlert1] = useState(false);
  const [showAlert2, setShowAlert2] = useState(false);
  const [singleBuyer, setSingleBuyer] = useState(false);
  const [informSuborderersText, setInformSuborderersText] = useState("");

  let navigate = useNavigate();

  const fillList = (cartItem) => {
    //Fills the list on the right side
    const type = cartItem.variation.weight;
    const quantity = cartItem.quantity;
    const name = cartItem.variation.parent.name;
    const productPrice = cartItem.variation.price;
    const variationId = cartItem.variation.variationId;
    const totalProductPrice = (
      Number(cartItem.total) + Number(cartItem.totalTax)
    ).toFixed(2);

    let newSelected = {
      name,
      type,
      productPrice,
      quantity,
      variationId,
      totalProductPrice,
    };

    return newSelected;
  };

  const Selected = ({ select }) => (
    //returns the component
    <CheeseListWrapper>
      <div style={{ display: "flex", flexDirection: "row" }}>
        <div style={{ marginRight: "10px" }}>
          <Paragraph>{select.quantity}x</Paragraph>
        </div>
        <div style={{ marginRight: "10px" }}>
          <Paragraph>
            {select.name}
            {"  "}
          </Paragraph>
        </div>
        <div>
          <Paragraph>{select.type}kg</Paragraph>
        </div>
      </div>
      <div>
        <Paragraph>€ {select.totalProductPrice.replace(".", ",")}</Paragraph>
      </div>
    </CheeseListWrapper>
  );

  if (error) return <Error404 />;
  if (loading)
    return (
      <Wrapper style={{ display: "flex", justifyContent: "center" }}>
        <ThreeDots height={400} width={200} color={colors.YellowBrigthButton} />
      </Wrapper>
    );

  if (!content?.order) return <Error404 />;

  const order = content.order;

  return (
    <Wrapper>
      <Content>
        <LoginContainer>
          <ContentContainer>
            <SmallHeading>Rechnungsadresse</SmallHeading>
            <Paragraph>
              {user?.billing.firstName} {user?.billing.lastName}
            </Paragraph>
            <Paragraph>{user?.billing.address1}</Paragraph>
            <Paragraph>
              {user?.billing.postcode} {user?.billing.city}
            </Paragraph>
            <Paragraph>{user?.billing.country}</Paragraph>
          </ContentContainer>
          <ContentContainer>
            <SmallHeading>Lieferadresse</SmallHeading>
            <Paragraph>
              {user?.shipping.firstName} {user?.shipping.lastName}
            </Paragraph>
            <Paragraph>{user?.shipping.address1}</Paragraph>
            <Paragraph>
              {user?.shipping.postcode} {user?.shipping.city}
            </Paragraph>
            <Paragraph>{user?.shipping.country}</Paragraph>
          </ContentContainer>
          <ContentContainer>
            <div style={{ height: "40px" }} />
            <SmallHeadingWithoutMargin>Bestellnummer</SmallHeadingWithoutMargin>
            <Paragraph>{order.orderNumber}</Paragraph>
            <SmallHeadingWithoutMargin>
              Bestellung abgesendet am:
            </SmallHeadingWithoutMargin>
            <Paragraph>
              {new Date(order.date).toLocaleDateString("DE-DE")}
            </Paragraph>
            {order.orderArrived?.orderarrived && (
              <>
                <SmallHeadingWithoutMargin>
                  Ware erhalten am:
                </SmallHeadingWithoutMargin>
                <Paragraph>{order.orderArrived.orderarrived}</Paragraph>
              </>
            )}
            {order.suborderersInformed && (
              <>
                <SmallHeadingWithoutMargin>
                  Besteller informiert am:
                </SmallHeadingWithoutMargin>
                <Paragraph>{order.suborderersInformed}</Paragraph>
              </>
            )}
          </ContentContainer>

          {!order.orderArrived?.orderarrived && (
            <Padding>
              <StyledButton
                onClick={() => {
                  setShowAlert1(true);
                }}
                scale={"ORDER"}
                title={"Ware erhalten"}
                color="#CCB242"
                fontSize={"20px"}
              />
            </Padding>
          )}
          {!order.suborderersInformed && !singleBuyer && (
            <Padding>
              <StyledButton
                onClick={() => {
                  setShowAlert2(true);
                }}
                scale={"ORDER"}
                title={"Besteller informieren"}
                color="#B2982A"
                fontSize={"20px"}
              />
            </Padding>
          )}
          {singleBuyer && (
            <Padding>
              <StyledButton
                onClick={() => {
                  window.open(order.invoiceUrl);
                }}
                scale={"ORDER"}
                title={"Rechnung herunterladen"}
                color="#8D7833"
                fontSize={"20px"}
              />
            </Padding>
          )}
          {!singleBuyer && (
            <Padding>
              <StyledButton
                onClick={() => {
                  setActiveView("Details");
                }}
                scale={"ORDER"}
                title={"Details"}
                color="#8D7833"
                fontSize={"20px"}
              />
            </Padding>
          )}
          <Padding>
            <StyledButton
              onClick={() => navigate("/bestellhistorie")}
              scale={"ORDER"}
              title={"zurück"}
              color={colors.YellowBrigthButton}
              fontSize={"20px"}
            />
          </Padding>
          {showAlert1 && (
            <Alert isVisible={showAlert1}>
              <Paragraph>Hast du deine Ware erhalten?</Paragraph>
              <div style={{ display: "flex", flexDirection: "row" }}>
                <div style={{ width: "49%" }}>
                  <StyledButton
                    onClick={() => {
                      setShowAlert1(false);
                    }}
                    scale={"ORDER"}
                    title={"abbrechen"}
                    color={colors.YellowBrigthButton}
                    fontSize={"20px"}
                  />
                </div>
                <div style={{ width: "2%" }} />
                <div style={{ width: "49%" }}>
                  <StyledButton
                    onClick={() => {
                      dateOrderArrived({
                        variables: {
                          orderId,
                          clientMutationId: "SetDateOrderArrived",
                          date: new Date().toLocaleDateString("DE-DE"),
                        },
                      })
                        .then((data) => {
                          setShowAlert1(false);
                        })
                        .catch((err) => console.error(err));
                    }}
                    scale={"ORDER"}
                    title={"bestätigen"}
                    color={colors.YellowBrigthButton}
                    fontSize={"20px"}
                    loading={orderArrivedLoading}
                  />
                </div>
              </div>
            </Alert>
          )}
          {showAlert2 && (
            <Alert isVisible={showAlert2}>
              {!informSuborderersText ? (
                <>
                  <Paragraph>
                    Möchtest du deine Mitbesteller informieren, dass die
                    Produkte bei dir eingetroffen und abholbereit sind?
                  </Paragraph>
                  <div style={{ display: "flex", flexDirection: "row" }}>
                    <div style={{ width: "49%" }}>
                      <StyledButton
                        onClick={() => {
                          setShowAlert2(false);
                        }}
                        scale={"OR"}
                        title={"abbrechen"}
                        color={colors.YellowBrigthButton}
                        fontSize={"20px"}
                      />
                    </div>
                    <div style={{ width: "2%" }} />
                    <div style={{ width: "49%" }}>
                      <StyledButton
                        onClick={() => {
                          informSuborderers({
                            variables: {
                              orderId,
                              clientMutationId: "InformOrderer",
                            },
                          })
                            .then(() => {
                              setInformSuborderersText(
                                "Besteller erfolgreich informiert!"
                              );
                            })
                            .catch((err) => {
                              setInformSuborderersText(err.message);
                            });
                        }}
                        scale={"ORDER"}
                        title={"bestätigen"}
                        color={colors.YellowBrigthButton}
                        fontSize={"20px"}
                        loading={informSuborderesLoading}
                      />
                    </div>
                  </div>
                </>
              ) : (
                <>
                  <Paragraph>{informSuborderersText}</Paragraph>
                  <img
                    alt={"test"}
                    style={{ maxWidth: "300px" }}
                    src={Brand}
                  ></img>
                  <div
                    style={{
                      width: "100%",
                      flexDirection: "row",
                      justifyContent: "center",
                    }}
                  >
                    <StyledButton
                      onClick={() => {
                        setShowAlert2(false);
                      }}
                      scale={"ORDER"}
                      title={"Zurück"}
                      color={colors.YellowBrigthButton}
                      fontSize={"20px"}
                    />
                  </div>
                </>
              )}
            </Alert>
          )}
        </LoginContainer>
        <ContainerWrapper>
          <SmallHeading>Bestellübersicht</SmallHeading>
          <div>
            {selected.map((select, index) => (
              <CheeseList key={index}>
                <Selected key={index} index={index} select={select} />
              </CheeseList>
            ))}
            <TotalWrapper>
              <div>
                <SmallHeading>Gesamt:</SmallHeading>
              </div>
              <div>
                <SmallHeading>
                  <strong>€ {order.total}</strong>
                </SmallHeading>
              </div>
            </TotalWrapper>
          </div>
        </ContainerWrapper>
      </Content>
    </Wrapper>
  );
};

const OrderDetails = ({ order, setActiveView }) => {
  return (
    <Wrapper>
      <Content>
        <LoginContainer>
          <ContentContainer>
            <SmallHeading>Rechnungsadresse</SmallHeading>
            <Paragraph>
              {order.billing.firstName} {order.billing.lastName}
            </Paragraph>
            <Paragraph>{order.billing.address1}</Paragraph>
            <Paragraph>
              {order.billing.postcode} {order.billing.city}
            </Paragraph>
            <Paragraph>{order.billing.country}</Paragraph>
          </ContentContainer>
          <ContentContainer>
            <SmallHeading>Lieferadresse</SmallHeading>
            <Paragraph>
              {order.shipping.firstName} {order.shipping.lastName}
            </Paragraph>
            <Paragraph>{order.shipping.address1}</Paragraph>
            <Paragraph>
              {order.shipping.postcode} {order.shipping.city}
            </Paragraph>
            <Paragraph>{order.shipping.country}</Paragraph>
          </ContentContainer>
          {
            <Padding>
              <OrderComplete order={order} color="#CCB242" />
            </Padding>
          }
          <Padding>
            <StyledButton
              onClick={() => {
                window.open(order.invoiceUrl);
              }}
              scale={"ORDER"}
              title={"Rechnung herunterladen"}
              color="#B2982A"
              fontSize={"20px"}
            />
          </Padding>
          <Padding>
            <StyledButton
              onClick={() => {
                setActiveView("Overview");
              }}
              scale={"ORDER"}
              title={"zurück"}
              color={colors.YellowBrigthButton}
              fontSize={"20px"}
            />
          </Padding>
        </LoginContainer>
        <ListWrapper>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              width: "100%",
              maxWidth: "450px",
            }}
          >
            <Accordion
              data={order.lists.lists}
              cart={order}
              orderId={order.orderId}
              parentPage="OrderOverviewWithId"
            />
          </div>
        </ListWrapper>
      </Content>
    </Wrapper>
  );
};

const OrderOverviewWithId = () => {
  const [activeView, setActiveView] = useState("Overview");
  const [order, setOrder] = useState();
  const navigation = [
    { actualName: ["Bestellhistorie"] },
    { codeName: ["Overview"] },
  ];
  const navigation2 = [
    { actualName: ["Bestellhistorie"] },
    { codeName: ["Details"] },
  ];

  if (activeView === "Overview")
    return (
      <OrderContainer
        navigation={navigation}
        clickable={false}
        noHeader={false}
        currentPage={activeView}
      >
        <OrderOverview
          setActiveView={setActiveView}
          onOrderChanged={(order) => setOrder(order)}
        />
      </OrderContainer>
    );
  else if (activeView === "Details") {
    return (
      <OrderContainer
        navigation={navigation2}
        clickable={false}
        noHeader={false}
        currentPage={activeView}
      >
        <OrderDetails order={order} setActiveView={setActiveView} />
      </OrderContainer>
    );
  } else {
    return null;
  }
};

const Wrapper = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
`;

const ListWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 3;
`;

const Content = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  background-color: white;
  gap: 5vw;
  @media (max-width: 1100px) {
    flex-direction: column-reverse;
  }
  @media (max-width: 400px) {
    padding: 10px;
    padding-top: 80px;
    width: 90vw;
  }
  @media (max-width: 320px) {
    padding-right: 0;
  }
`;

const LoginContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

const ContainerWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 3;
`;

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;

  p {
    margin: 0;
  }
`;

const CheeseList = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  @media (max-width: 800px) {
    width: unset;
  }
`;

const SmallHeading = styled.h4`
  color: #4d4d4d;
  font-size: 16px;
  font-family: "Montserrat";
  font-weight: 300;
  letter-spacing: 0.4px;
  margin-top: 0;
`;

const SmallHeadingWithoutMargin = styled.h4`
  color: #4d4d4d;
  font-size: 16px;
  font-family: "Montserrat";
  font-weight: 300;
  letter-spacing: 0.4px;
  margin: 0;
`;

const Paragraph = styled.p`
  color: #808080;
  font-size: 16px;
  font-family: "Montserrat";
  font-weight: 300;
  letter-spacing: 0.4px;
`;

const Padding = styled.div`
  padding: 3px 0;
`;

const CheeseListWrapper = styled.div`
  width: 400px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border-top: 1px solid #e1e1e1;
  @media (max-width: 430px) {
    width: 100vw;
  }
`;

const CircleWrapper = styled.div`
  position: absolute;
  right: 15vw;
  top: 11vh;
  @media (max-width: 850px) {
    right: 6vw;
  }
  @media (max-width: 775px) {
    top: 20vh;
  }
  @media (max-width: 425px) {
    display: none;
  }
`;

const TotalWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 400px;
  border-top: 2px solid #e1e1e1;
  padding-top: 10px;
  @media (max-width: 570px) {
    width: unset;
  }
`;

export default OrderOverviewWithId;
