import queryString from "query-string";
import { ThreeDots } from "react-loader-spinner";
import Cookies from "universal-cookie";
import AddProduct from "../../components/AddProduct";
import OrderContainer from "../../components/OrderContainer";
import RegisterSuborderer from "../../components/RegisterSuborderer";
import ThanksScreen from "../../components/ThanksScreen";
import {
  colors,
  gql,
  React,
  useContext,
  useEffect,
  useQuery,
  useState,
} from "../../imports";
import { GetCurrentPage } from "../../localStorage/Store";
import { useProductList } from "./useProductList";

const getAllProducts = gql`
  query getProducts {
    __typename
    products(
      where: { status: "publish", orderby: { field: DATE, order: ASC } }
      first: 20
    ) {
      nodes {
        name
        ... on VariableProduct {
          variations {
            nodes {
              weight
              sku
              price
              variationId
            }
          }
        }
        productId
      }
    }
  }
`;
const GetInitiatorName = gql`
  query getInitiatorNameQuery($key: String!) {
    __typename
    initiatorInfo(subordererKey: $key) {
      bank
      iban
      name
    }
  }
`;
const GetExtCartItems = gql`
  query getExtCartItems($key: String!) {
    __typename
    extCart(subordererKey: $key) {
      total
      addProductsUntil
    }
  }
`;

const CheckIfOrderedQuery = gql`
  query MyQuery($key: String!) {
    __typename
    checkIfOrdered(subordererKey: $key)
  }
`;

const DataInputSubbesteller = () => {
  let params = queryString.parse(window.location.search);
  const key2 = params.id;
  const name2 = params.name;
  const key = decodeURIComponent(key2);
  const accountType = params.type;
  const cookies = new Cookies();
  const navigation = [
    { actualName: ["Benutzerdaten", "Bestellung"] },
    { codeName: ["RegisterSuborderer", "AddProduct"] },
  ];
  const navigation2 = [
    { actualName: ["Bestellung"] },
    { codeName: ["AddProduct"] },
  ];

  const [initiator, setInititator] = useState();
  const [alreadyOrdered, setAlreadyOrdered] = useState(false);
  const [totalCart, setTotalCart] = useState();
  const [currentPage, setCurrentPage] = useState(
    cookies.get("AuthToken") ? "AddProduct" : "RegisterSuborderer"
  );
  const { productList, setter } = useProductList();
  const { setDuration, durationFromBackend, setDurationFromBackend } =
    useContext(GetCurrentPage);

  const { loading, error, data } = useQuery(getAllProducts);
  const { data: extCart, refetch } = useQuery(GetExtCartItems, {
    notifyOnNetworkStatusChange: true,
    fetchPolicy: "network-only",
    variables: { key },
    onCompleted: (CartItems) => {
      setTotalCart(CartItems.extCart.total);
    },
  });
  const { data: dataCheck } = useQuery(CheckIfOrderedQuery, {
    fetchPolicy: "network-only",
    notifyOnNetworkStatusChange: true,
    variables: { key: key },
  });
  const {} = useQuery(GetInitiatorName, {
    variables: { key },
    onCompleted: (Name) => {
      setInititator(Name);
      if (alreadyOrdered) {
        handleView("ThanksScreen");
      }
    },
  });

  const handleView = (pageName) => {
    refetch();
    setCurrentPage(pageName);
  };

  useEffect(() => {
    if (extCart) {
      if (
        extCart?.extCart &&
        extCart.extCart.addProductsUntil &&
        extCart.extCart.addProductsUntil !== 0 &&
        extCart.extCart.addProductsUntil !== null
      ) {
        let date = new Date(extCart.extCart?.addProductsUntil * 1000);
        let currentDate = new Date();

        // let day = parseInt(Math.round(date.getTime() - currentDate.getTime())/ (1000 * 60 * 60 * 24))|| 0
        let tmpTime = date.getTime() - currentDate.getTime();
        var tmpDays = tmpTime / (1000 * 3600 * 24);
        if (tmpDays < 0) {
          setDuration("Bestelldauer ist abgelaufen.");
        } else if (tmpDays < 0.5) {
          setDuration(date.getHours() + ":" + date.getMinutes() + " Uhr");
        } else {
          setDuration(Math.round(tmpDays));
        }
        setDurationFromBackend(true);
      } else {
        setDurationFromBackend(false);
      }
    }
  }, [extCart]);

  useEffect(() => {
    if (dataCheck?.checkIfOrdered) {
      handleView("ThanksScreen");

      setAlreadyOrdered(true);
    }
  }, [dataCheck]);

  if (error) return <p>Error</p>;
  if (loading)
    return (
      <div style={{ display: "flex", justifyContent: "center" }}>
        <ThreeDots height={400} width={200} color={colors.YellowBrigthButton} />
      </div>
    );

  return (
    <OrderContainer
      activeView={(pageName) => handleView(pageName)}
      currentPage={currentPage}
      navigation={accountType === "inviteLink" ? navigation : navigation2}
      clickable={false}
      minheight4k={800}
    >
      {currentPage === "AddProduct" ? (
        <AddProduct
          data={data.products.nodes}
          parentPage={"DataInputSubbesteller"}
          accountType={accountType}
          subordererKey={key}
          saveOrder={() => {}}
          activeView={(pageName) => handleView(pageName)}
          setter={setter}
          productList={productList}
        />
      ) : currentPage === "RegisterSuborderer" ? (
        <RegisterSuborderer activeView={(pageName) => handleView(pageName)} />
      ) : (
        <ThanksScreen
          location={"DataInputSubbesteller"}
          subordererKey={key}
          total={totalCart?.toFixed(2).toString().replace(".", ",")}
          alreadyOrdered={true}
          initiator={initiator}
        ></ThanksScreen>
      )}
    </OrderContainer>
  );
};

export default DataInputSubbesteller;
