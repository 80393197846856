// Created by:      Marcel Simma
// Copyright:       © 2020 Zewas OG
// Creation date:   08.08.2020
// Last edited:     09.08.2020
import React, { useRef } from "react";
import { ThreeDots } from "react-loader-spinner";
import styled from "styled-components";
import Cookies from "universal-cookie";
import Brand from "../assets/images/Brand.jpg";
import colors from "../constants/colors";
import HeadingOne from "./HeadingOne";
import OrderComplete from "./OrderComplete";
import constants from "../constants/constants";
import StyledButton from "./StyledButton";
import Girocode from "react-girocode";
import dateFormat from "dateformat";
import { i18n } from "dateformat";

i18n.monthNames = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "Mai",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Okt",
  "Nov",
  "Dez",
  "Jänner",
  "Februar",
  "März",
  "April",
  "Mai",
  "Juni",
  "Juli",
  "August",
  "September",
  "Oktober",
  "November",
  "Dezember",
];

const cookies = new Cookies();

/**
 * @author Sandro Mennel
 * @param {string} location the parentLocation
 * @param {string} order the orderData
 * @param {string} total the subordererTotal
 * @param {string} initiator the initiator
 * @example <ThanksScreen order={order} location={"SingleBuyerPage"} />
 */

const ThanksScreen = ({
  order,
  location,
  total,
  initiator,
  alreadyOrdered,
}) => {
  const ref = useRef(null);
  if (total === undefined && location === "DataInputSubbesteller")
    return (
      <ThreeDots height={200} width={100} color={colors.YellowBrigthButton} />
    );
  return (
    //  <DataContainer location={location}>
    <DataContainer>
      <div>
        <HeadingOne title="Vielen Dank für deine Bestellung!" />
      </div>
      <Content location={location}>
        <StyledBrand src={Brand} />

        {location !== "DataInputSubbesteller" ? (
          <Initiator style={{ maxWidth: "500px", marginTop: "50px" }}>
            <Paragraph>
              Bitte überweise den Betrag von <strong>€ {order?.total}</strong>{" "}
              vorab auf das angegebene Konto und führe den Verwendungszweck an.
            </Paragraph>
            <Row style={{ marginTop: "15px" }}>
              <Column ref={ref}>
                <Paragraph>
                  Zahlungsempfänger: <strong>Melchior Simma</strong>
                  <br />
                  IBAN: <strong>AT22 2060 3000 0208 3681</strong>
                  <br />
                  BIC: <strong>SPEGAT21XXX</strong>
                  <br />
                  Überweisungsbetrag: <strong>€ {order?.total}</strong>
                  <br />
                  Verwendungszweck: <strong>{order?.orderNumber}</strong>
                </Paragraph>

                {order && (
                  <div style={{ marginTop: "10px" }}>
                    <StyledButton
                      onClick={() => {
                        window.location.href = "./bestellhistorie";
                      }}
                      scale={"ORDER"}
                      title={"Bestellhistorie"}
                      color={colors.YellowBrigthButton}
                      fontSize={"20px"}
                    />
                  </div>
                )}
              </Column>
              {ref.current?.offsetHeight && (
                <QRCode $height={ref.current.offsetHeight}>
                  <Girocode
                    recipient="Melchior Simma"
                    iban="AT22 2060 3000 0208 3681"
                    amount={parseFloat(order?.total?.replace(",", "."))}
                    text={order?.orderNumber}
                  />
                </QRCode>
              )}
            </Row>
          </Initiator>
        ) : (
          <Initiator style={{ maxWidth: "500px", marginTop: "50px" }}>
            <Paragraph>
              Bitte halte den Betrag von{" "}
              <strong>
                €{" "}
                {parseFloat(total?.replace(",", "."))
                  .toFixed(2)
                  .toString()
                  .replace(".", ",")}
              </strong>{" "}
              für {initiator?.initiatorInfo?.name} bereit, oder überweise diesen
              direkt auf das angegebene Konto.
            </Paragraph>
            <Row style={{ marginTop: "15px" }}>
              <Paragraph ref={ref} style={{ hyphens: "auto" }}>
                Zahlungsempfänger:{" "}
                <strong>{initiator?.initiatorInfo?.name}</strong>
                <br />
                {initiator?.initiatorInfo?.iban && (
                  <>
                    IBAN: <strong>{initiator?.initiatorInfo?.iban}</strong>
                  </>
                )}
                <br />
                Überweisungsbetrag:{" "}
                <strong>
                  €{" "}
                  {parseFloat(total?.replace(",", "."))
                    .toFixed(2)
                    .toString()
                    .replace(".", ",")}
                </strong>
                <br />
                Verwendungszweck:{" "}
                <strong>
                  gut gereift, Käsebestellung {dateFormat(new Date(), "mmmm")}
                </strong>
              </Paragraph>
              {initiator?.initiatorInfo?.iban && ref.current?.offsetHeight && (
                <QRCode
                  $height={ref.current.offsetHeight}
                  style={{ display: "flex", justifyContent: "center" }}
                >
                  <Girocode
                    recipient={initiator?.initiatorInfo?.name}
                    iban={initiator?.initiatorInfo?.iban}
                    amount={parseFloat(total?.replace(",", "."))}
                    text={`gut gereift, Käsebestellung ${dateFormat(
                      new Date(),
                      "mmmm"
                    )}`}
                  />
                </QRCode>
              )}
            </Row>
            {alreadyOrdered && (
              <Paragraph style={{ marginTop: "15px" }}>
                Deine Bestellung ist bereits abgeschlossen. <br /> Bitte
                kontaktiere für Änderungen den/die BestellinitiatorIn.{" "}
              </Paragraph>
            )}
          </Initiator>
        )}
      </Content>
    </DataContainer>
  );
};

const StyledBrand = styled.img`
  max-width: 494px;
  width: 100%;
  height: auto;
  align-self: center;
  margin-top: 50px;
  @media (max-width: 2000px) {
    max-width: 400px;
  }
`;
const Text = styled.p`
  margin: 0;
`;
const DataParagraph = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

const DataContainer = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
`;
const Content = styled.div`
  display: flex;
  flex-direction: row;
  /* flex-direction: row; */
  justify-content: space-between;
  @media (max-width: 1500px) {
    flex-direction: column;
    width: 100%;
    /* height: 65vh; */
  }
  @media (max-width: 700px) {
    flex-direction: column;
    width: unset;
    /* height: 65vh; */
  }
`;

const Paragraph = styled.p`
  color: #808080;
  font-size: 16px;
  letter-spacing: 0.4px;
  font-family: "Montserrat";
  font-weight: 300;
  padding: 0;
  margin: 0;
`;

const Initiator = styled.div`
  max-width: 500px;
  margin-top: 50px;
  margin-left: 100px;
  @media (max-width: 1500) {
    margin-left: 0;
  }
  @media (max-width: ${constants.nexusPortraitWidth}px) {
    margin-left: 0;
  }
`;
const Row = styled.div`
  display: flex;
  flex-direction: row;
  gap: 20px;
  align-items: center;
  @media (max-width: ${constants.iPhoneLandscapeWidth}px) {
    flex-direction: column;
    align-items: flex-start;
  }
`;
const QRCode = styled.div`
  display: flex;
  background-color: red;
  svg {
    width: auto;
    height: ${({ $height }) => $height + "px"};
    @media (max-width: ${constants.iPhoneLandscapeWidth}px) {
      height: 150px;
    }
  }
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
`;
export default ThanksScreen;
