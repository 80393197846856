// Created by:      Marcel Simma
// Copyright:       © 2020 Zewas OG
// Creation date:   31.07.2020
// Last edited:     02.08.2020

import React from "react";
import styled from "styled-components";
import styl from "../styl";
import constants from "../constants/constants";
import colors from "../constants/colors";
import Header from "./styledHeader/StyledHeader";
import Footer from "./Footer";
import PropTypes from "prop-types";
import Texture from "../assets/images/texture.jpg";

// Props:
// - showHeader: Boolean (required)
// -- Defines if the Header should be shown or hidden
// - background: String (required)
// -- Defines the background of the page
// -- If no value is provided the background will be a texture.
// -- If a value is provided it should be a valid color name or code.
// - children
// -- Contains the child components.
// - footerFontColor
// -- Sets the link items' text color.
// -- If no footerFontColor is provided, the footerFontColor will be #4D4D4D by default.

//  <DefaultWindow showHeader={false} footerFontColor={colors.SolidWhiteBackground} background={colors.SolidGreyText}>
//      <Landingpage />
//  </DefaultWindow>

const DefaultWindow = ({
  showHeader = true,
  background = "texture",
  children,
  footerFontColor,
  showFooter,
}) => {
  return (
    <BrowserWindow>
      {showHeader ? <Header /> : null}
      <Main prop_background={background}>{children}</Main>
      <Footer fontColor={footerFontColor} showFooter={showFooter} />
    </BrowserWindow>
  );
};

DefaultWindow.propTypes = {
  showHeader: PropTypes.bool.isRequired,
  background: PropTypes.string.isRequired,
  children: PropTypes.any,
  footerFontColor: PropTypes.string.isRequired,
};

const BrowserWindow = styled.div`
  ${styl.flexColumn};
  background: black;
  min-height: 100vh;
  min-width: 320px;
  position: relative;
`;

const Main = styled.div`
  background: ${(props) =>
    props.prop_background === "texture"
      ? `url(${Texture})`
      : props.prop_background};

  min-height: 100%;
  flex: 1;
  ${styl.flexColumn};

  @media (max-width: ${constants.nexusPortraitWidth}px) {
    background: ${colors.Cararra};
  }
  @media (max-width: ${constants.mobile}) {
    flex: auto;
  }
`;

export default DefaultWindow;
