import { FaPen, FaTrashAlt } from "react-icons/fa";
import {
  colors,
  React,
  styled,
  useEffect,
  useState,
  constants,
} from "../../imports";
import styl from "../../styl";
import Alert from "../Alert";
import StateInput from "../StateInput";
import StyledButton from "../StyledButton";
import useWindowSize from "../hooks/useWindowSize";
import validator from "validator";

const EditListComp = ({
  cancel,
  save,
  personsInList,
  removePersonFromList,
  list,
  removeList,
  edit,
}) => {
  const [countInput, setCountInputState] = useState([""]);
  const [stateInput, setStateInput] = useState([
    { firstName: "", lastName: "", email: "" },
  ]);
  const [listName, setListName] = useState("");
  const [loadRemovePerson, setLoadRemovePerson] = useState(false);
  const [loadingAddPeople, setLoadingAddPeople] = useState(false);
  const [showDeleteAlert, setShowDeleteAlert] = useState(false);
  const [showAlert2, setShowAlert2] = useState(false);
  const [editArray, setEditArray] = useState();
  const [editMode, setEditMode] = useState(false);
  const [editFirstName, setEditFirstName] = useState("");
  const [editLastName, setEditLastName] = useState("");
  const [editEmail, setEditEmail] = useState("");
  const [editRowId, setEditRowId] = useState("");
  const [deletePerson, setDeletePerson] = useState();
  const [emailValidation, setEmailValidation] = useState([false]);
  const [firstNameValidation, setFirstNameValidation] = useState([false]);
  const [lastNameValidation, setLastNameValidation] = useState([false]);
  const [editFirstNameError, setEditFirstNameError] = useState("");
  const [editLastNameError, setEditLastNameError] = useState("");
  const [editEmailError, setEditEmailError] = useState("");
  const size = useWindowSize();

  // const
  const editPerson = (person, i) => {
    setEditMode(true);
    setEditFirstName(person.firstName);
    setEditLastName(person.lastName);
    setEditEmail(person.email);
    setEditRowId(person.rowId);
    setListName(list);
    let tmp = [];

    personsInList.map((item, i2) => {
      if (i2 == i) {
        tmp.push(true);
      } else {
        tmp.push(false);
      }
    });
    setEditArray(tmp);
    setDeletePerson(person);
    setEditArray(tmp);

    // removePersonFromList(person);
  };
  useEffect(() => {
    setListName(list);
  }, [list]);
  useEffect(() => {
    let tmp = [];

    personsInList.map((item) => {
      tmp.push(false);
    });
    setEditArray(tmp);
  }, [removePersonFromList]);

  function CheckEmail(emails) {
    let errors = [];
    var mailformat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    let foundError = false;
    let tmp = emails.filter(
      (item) => item.firstName != "" || item.lastName != "" || item.email != ""
    );

    tmp.map((item) => {
      if (validator.isEmail(item.email)) {
        errors.push(false);
      } else {
        foundError = true;
        errors.push(true);
      }
    });
    setEmailValidation(errors);

    return !foundError;
  }

  function CheckData(input) {
    let errorsFirstName = [];
    let errorsLastName = [];
    let foundError = false;
    let tmp = input.filter(
      (item) => item.firstName != "" || item.lastName != "" || item.email != ""
    );
    tmp.map((item) => {
      if (item.firstName == "") {
        errorsFirstName.push(true);
        foundError = true;
      } else {
        errorsFirstName.push(false);
      }
      if (item.lastName == "") {
        errorsLastName.push(true);
        foundError = true;
      } else {
        errorsLastName.push(false);
      }
    });
    setFirstNameValidation(errorsFirstName);
    setLastNameValidation(errorsLastName);

    return !foundError;
  }

  return (
    <Main>
      <TableMain>
        <ListNameWrapper>
          <StateInput
            name={"Liste"}
            forwardedRef={listName}
            onChange={(e) => {
              setListName(e.target.value);
              // debounce(()=>changeListName(), 200)
            }}
            type={"text"}
            placeholder={list.name}
            maxLength="20"
            size={"big"}
            // style={{padding: "0 !important"}}
          />
        </ListNameWrapper>
        {personsInList.length != 0 &&
          personsInList.map((person, i) => {
            return (
              <LineWrapper>
                {editMode && editArray && editArray[i] ? (
                  <InputWrapper>
                    <NameWrapper>
                      <NameSpacer2>
                        <StateInput
                          name={"Vorname"}
                          forwardedRef={editFirstName}
                          onChange={(e) => {
                            setEditFirstName(e.target.value);
                          }}
                          type={"text"}
                          error={editFirstNameError}
                          placeholder={"Vorname"}
                          maxLength="40"
                        />
                      </NameSpacer2>
                      <NameSpacer>
                        <StateInput
                          name={"Nachname"}
                          forwardedRef={editLastName}
                          onChange={(e) => {
                            setEditLastName(e.target.value);
                          }}
                          error={editLastNameError}
                          type={"text"}
                          placeholder={"Nachname"}
                          maxLength="40"
                        />
                      </NameSpacer>
                    </NameWrapper>
                    <EmailWrapper>
                      <StateInput
                        name={"Email"}
                        error={editEmailError}
                        forwardedRef={editEmail}
                        onChange={(e) => {
                          setEditEmail(e.target.value);
                        }}
                        type={"text"}
                        placeholder={"Email"}
                        maxLength="100"
                      />
                    </EmailWrapper>
                  </InputWrapper>
                ) : (
                  //Normal line, without editing mode
                  <InputWrapper>
                    <PlainData width="60%">
                      <FirstLastName style={{ paddingRight: "4px" }}>
                        {person.firstName + " "}
                      </FirstLastName>
                      <FirstLastName>{person.lastName}</FirstLastName>
                    </PlainData>
                    <PlainData width="60%">{person.email}</PlainData>
                  </InputWrapper>
                )}
                {/* Edit Buttons and edit texts */}
                {editArray && !editArray[i] && (
                  <Data>
                    {size.width > 900 ? (
                      <IconWrapper>
                        <FaPen
                          style={{
                            color: "#B2B2B2",
                            cursor: "pointer",
                            marginRight: "25px",
                          }}
                          onClick={() => {
                            editPerson(person, i);
                          }}
                        />
                        <FaTrashAlt
                          style={{
                            color: "#B2B2B2",
                            cursor: "pointer",
                          }}
                          onClick={() => {
                            setShowAlert2(true);
                            setDeletePerson(person);
                          }}
                        />
                      </IconWrapper>
                    ) : (
                      <IconWrapper>
                        <IconText
                          onClick={() => {
                            editPerson(person, i);
                          }}
                          style={{ marginRight: "10px" }}
                        >
                          Kontakt bearbeiten
                        </IconText>
                        <IconText
                          onClick={() => {
                            setShowAlert2(true);
                            setDeletePerson(person);
                          }}
                        >
                          Kontakt löschen
                        </IconText>
                      </IconWrapper>
                    )}
                    {showAlert2 && (
                      <Alert isVisible={showAlert2}>
                        <ParagraphRight>
                          Der Besteller wird aus allen <br /> laufenden
                          Bestellungen entfernt.
                        </ParagraphRight>
                        <ParagraphRightYellow>
                          Wirklich entfernen?
                        </ParagraphRightYellow>
                        <RowWrapper>
                          <StyledButton
                            onClick={() => setShowAlert2(false)}
                            title="abbrechen"
                            color={colors.YellowBrigthButton}
                          />
                          <div style={{ width: "10px" }} />
                          <StyledButton
                            onClick={async () => {
                              setLoadRemovePerson(true);
                              if (await removePersonFromList(deletePerson)) {
                                setShowAlert2(false);
                                setLoadRemovePerson(false);
                              }
                            }}
                            loading={loadRemovePerson}
                            title="entfernen"
                            color="#B2982A"
                          />
                        </RowWrapper>
                      </Alert>
                    )}
                  </Data>
                )}
              </LineWrapper>
            );
          })}
        {!editMode &&
          countInput.map((item, i) => {
            return (
              <InputWrapper>
                <NameWrapper>
                  <NameSpacer2>
                    <StateInput
                      name={"Vorname"}
                      forwardedRef={stateInput[i]?.firstName}
                      error={firstNameValidation[i] && "Vornamen eingeben"}
                      onChange={(e) => {
                        let tmp = stateInput.slice();
                        tmp[i].firstName = e.target.value;
                        setStateInput(tmp);
                        if (!countInput.includes(i)) {
                          setCountInputState([...countInput, i]);
                          setEmailValidation([...emailValidation, false]);
                          setFirstNameValidation([
                            ...firstNameValidation,
                            false,
                          ]);
                          setLastNameValidation([...lastNameValidation, false]);
                        }
                        setStateInput([
                          ...stateInput,
                          {
                            firstName: "",
                            lastName: "",
                            email: "",
                          },
                        ]);
                      }}
                      type={"text"}
                      placeholder={"Vorname"}
                      maxLength="40"
                    />
                  </NameSpacer2>
                  <NameSpacer>
                    <StateInput
                      name={"Nachname"}
                      forwardedRef={stateInput[i]?.lastName}
                      error={lastNameValidation[i] && "Nachname eingeben"}
                      onChange={(e) => {
                        let tmp = stateInput.slice();
                        tmp[i].lastName = e.target.value;
                        setStateInput(tmp);
                      }}
                      type={"text"}
                      placeholder={"Nachname"}
                      maxLength="40"
                    />
                  </NameSpacer>
                </NameWrapper>
                <EmailWrapper>
                  <StateInput
                    name={"Email"}
                    error={emailValidation[i] && "Emailformat nicht gültig"}
                    forwardedRef={stateInput[i]?.email}
                    onChange={(e) => {
                      let tmp = stateInput.slice();
                      tmp[i].email = e.target.value;
                      setStateInput(tmp);
                    }}
                    type={"text"}
                    placeholder={"Email"}
                    maxLength="100"
                  />
                </EmailWrapper>
              </InputWrapper>
            );
          })}
      </TableMain>
      <WrapperButton>
        <StyledButton
          onClick={async () => {
            setLoadingAddPeople(true);
            setEditFirstNameError("");
            setEditLastNameError("");
            setEditEmailError("");
            if (editMode) {
              if (
                CheckEmail([{ email: editEmail }]) &&
                editFirstName != "" &&
                editLastName != "" &&
                editEmail != ""
              ) {
                // if (await removePersonFromList(deletePerson)) {
                //     await save([{ firstName: editFirstName, lastName: editLastName, email: editEmail }], listName)

                // }
                await edit(deletePerson, {
                  firstName: editFirstName,
                  lastName: editLastName,
                  email: editEmail,
                });
                setEditMode(false);
                setDeletePerson();
                setLoadingAddPeople(false);
              } else {
                if (editFirstName == "") {
                  setEditFirstNameError("Vornamen eingeben");
                }
                if (editLastName == "") {
                  setEditLastNameError("Nachnamen eingeben");
                }
                if (!CheckEmail([{ email: editEmail }]) || editEmail == "") {
                  setEditEmailError("Emailformat ungültig");
                }
                setLoadingAddPeople(false);
              }
            } else {
              if (CheckData(stateInput) && CheckEmail(stateInput)) {
                if (save(stateInput, listName)) {
                  setStateInput([{ firstName: "", lastName: "", email: "" }]);
                  setCountInputState([""]);
                  setEmailValidation([false]);
                  setLoadingAddPeople(false);
                } else {
                }
              } else {
                setLoadingAddPeople(false);
              }
            }
          }}
          loading={loadingAddPeople}
          scale={"ORDER"}
          title={"speichern"}
          color="#8D7833"
          fontSize={"20px"}
        />
        <div style={{ height: "5px" }} />
        <StyledButton
          onClick={() => cancel()}
          title="zurück"
          color={colors.YellowBrigthButton}
          scale={"ORDER"}
          fontSize={"20px"}
        />
        <div
          style={{ height: "70px", display: "flex", alignItems: "Flex-end" }}
        >
          <Link
            onClick={() => {
              setShowDeleteAlert(true);
            }}
          >
            Liste Löschen
          </Link>
        </div>
      </WrapperButton>
      {showDeleteAlert && (
        <Alert isVisible={showDeleteAlert}>
          <ParagraphRight>
            Die Liste wird aus allen <br /> laufenden Bestellungen entfernt.
          </ParagraphRight>
          <ParagraphRightYellow>Wirklich entfernen?</ParagraphRightYellow>
          <RowWrapper>
            <StyledButton
              onClick={() => setShowDeleteAlert(false)}
              title="abbrechen"
              color={colors.YellowBrigthButton}
            />
            <div style={{ width: "10px" }} />
            <StyledButton
              onClick={async () => {
                removeList();
              }}
              title="entfernen"
              color="#B2982A"
            />
          </RowWrapper>
        </Alert>
      )}
    </Main>
  );
};

const Main = styled.div`
  flex: 1;
  @media (max-width: ${constants.nexusPortraitWidth}px) {
    display: flex;
    /* justify-content: center; */
    flex-direction: column;
  }
`;
const TableMain = styled.div`
  width: 66%;
  margin-left: 280px;
  display: flex;
  flex-direction: column;
  @media (max-width: 1520px) {
    width: 80%;
  }
  @media (max-width: ${constants.nexusPortraitWidth}px) {
    margin-left: unset;
    width: 100%;
  }
`;
const ListNameWrapper = styled.div`
  width: 200px;
  margin-bottom: 10px;
  margin-top: 0;
  /* padding: 0 !important; */
  @media (max-width: ${constants.nexusPortraitWidth}px) {
    margin-top: 15px;
  }
`;
const LineWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  @media (max-width: 900px) {
    flex-direction: column;
    align-items: unset;
  }
  @media (max-width: ${constants.nexusPortraitWidth}px) {
    border-top: 1px ${colors.MercuryGreyInputfields} solid;
  }
`;
const InputWrapper = styled.div`
  width: 90%;
  display: flex;
  flex-direction: row;
  @media (max-width: 1330px) {
    flex-direction: column;
    width: 70%;
  }
  @media (max-width: ${constants.nexusPortraitWidth}px) {
    width: 100%;
  }
`;
const FirstLastName = styled.div`
  display: inline-flex;
  word-break: break-word;
`;
const NameWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  width: 47%;
  margin-right: 3%;
  @media (max-width: 1520px) {
    justify-content: unset;
  }
  @media (max-width: 1330px) {
    width: unset;
    margin-right: unset;
  }
`;
const EmailWrapper = styled.div`
  width: 40%;
  @media (max-width: 1330px) {
    width: 49%;
  }
`;
const NameSpacer = styled.div`
  width: 49%;
`;
const NameSpacer2 = styled(NameSpacer)`
  @media (max-width: 1520px) {
    margin-right: 12.5px;
  }
  @media (max-width: 1330px) {
    margin-right: 1%;
  }
`;
const Data = styled.div`
  color: #808080;
  font-family: "Montserrat";
  font-weight: 300;
  letter-spacing: 0.4px;
  font-size: 16px;
  height: 30px;
  display: flex;
  align-items: center;
  margin: 0;
  white-space: unset;
  padding: 0;
  overflow-wrap: break-word;
`;
const PlainData = styled(Data)`
  height: unset;
  display: unset;
  align-items: unset;
  margin: 5px 0;
  width: ${(props) => props.width};
  @media (max-width: 900px) {
    width: 100%;
  }
`;
const ParagraphRightYellow = styled.p`
  color: #b2982a;
  font-size: 16px;
  letter-spacing: 0.4px;
  font-family: "Montserrat";
  font-weight: 300;
  padding: 0;
  text-align: right;
`;

const RowWrapper = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 5px;
  @media (max-width: 770px) {
    flex-direction: column;
    height: 120px;
  }
`;

const ParagraphRight = styled.p`
  color: #808080;
  font-size: 16px;
  letter-spacing: 0.4px;
  font-family: "Montserrat";
  font-weight: 300;
  padding: 0;
  text-align: right;
  line-height: 1.2;
`;

const Link = styled.a`
  font-family: "Montserrat";
  color: #808080;
  font-size: 16px;
  text-decoration: underline;
  cursor: pointer;
`;

const WrapperButton = styled.div`
  position: fixed;
  top: 0;
  margin-top: 11vh;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  height: 426px;
  @media (max-width: 1025px) {
    height: 386px;
  }
  @media (max-width: ${constants.nexusPortraitWidth}px) {
    position: unset;
    height: unset;
    display: flex;
    /* align-items: center; */
    margin-top: 30px;
  }
`;

const IconWrapper = styled.div`
  @media (max-width: 900px) {
    display: flex;
  }
`;
const IconText = styled.div`
  font-size: 12px;
  text-decoration: underline;
  color: ${colors.YellowBrigthButton};
  cursor: pointer;
  &:hover {
    opacity: 0.6;
  }
`;

export default EditListComp;
