import { gql, useLazyQuery, useMutation, useQuery } from "@apollo/client";
import React, { useContext, useEffect, useRef, useState } from "react";
import { ThreeDots } from "react-loader-spinner";
import styled from "styled-components";
import colors from "../constants/colors";
import { GetCurrentPage, GetUserContext } from "../localStorage/Store";
import Error404 from "../pages/error/Error404";
import AccordionComp from "./Accordion/Accordion";
import Alert from "./Alert";
import CircleWithText from "./CircleWithText";
import RefInput from "./RefInput";
import SingleSelect from "./SingleSelect";
import StyledButton from "./StyledButton";
import Brand from "../assets/images/Brand.jpg";

const GetExtCartItems = gql`
  query getExtCartItems($subordererKey: String!, $coolboxDisabled: Boolean) {
    __typename
    extCart(subordererKey: $subordererKey, coolboxDisabled: $coolboxDisabled) {
      lists {
        name
        total
        orderers {
          products {
            amount
            rowId
            total
            product {
              parent {
                name
                coolbox {
                  coolboxActivated
                  coolboxWeight
                }
              }
              name
              price
              weight
              variationId
              id
            }
          }
          name
          total
          paid
          paidRowId
        }
      }
      coolboxRecommended
      total
      coolbox
      shipping
      subtotalWithTax
      minimumOrderWeight
      totalWeight
      isNewCustomer
    }
  }
`;

const CheckCouponCode2 = gql`
  query MyQuery($code: String!) {
    __typename
    checkCoupon(code: $code) {
      amount
      type
      valid
    }
  }
`;
const CheckCoolBoxForce = gql`
  query CoolboxForce {
    generalOptions {
      coolbox_shipment {
        coolboxForced
      }
    }
  }
`;

const setPriceNameMutation = gql`
  mutation updateCustomer($id: ID, $showName: Boolean, $showPrice: Boolean) {
    __typename
    updateCustomer(
      input: {
        clientMutationId: "UpdateCustomer"
        showName: $showName
        showPrice: $showPrice
        id: $id
      }
    ) {
      customer {
        billing {
          firstName
          address1
          city
          country
          email
          lastName
          phone
          postcode
          state
        }
        shipping {
          address1
          city
          postcode
          state
          country
          firstName
          lastName
          email
          phone
        }
        hasCoupon
        orderCount
        lastName
        firstName
        email
        customerId
        id
        username
        iban
        bank
        showName
        showPrice
      }
    }
  }
`;
/**
 * @author Samuel Plankel
 * @param {function} saveOrder the function which is called when the complete Order button has been fired
 * @param {funciton} activeView the function which is called when the back or complete Order Button has been fired
 * @param {string} subordererKey the key of the order where Suborderer can join and add to cart
 * @param {string} parentPage if the component is called from the MultipleBuyerPage
 * @param {Object} customer the customer if it is not the same as the userData
 * @example <OrderOverview parentPage={'MultipleBuyerPage'} subordererKey={""} saveOrder={() => SaveOrder()} activeView={(pageName) => handleView(pageName)} />
 */

const OrderOverview = ({
  saveOrder,
  activeView,
  subordererKey,
  parentPage,
  customer,
}) => {
  const {
    loading: loadingExtCart,
    error,
    data: content,
    refetch,
  } = useQuery(GetExtCartItems, {
    notifyOnNetworkStatusChange: true,
    fetchPolicy: "network-only",
    variables: { subordererKey, coolboxDisabled: false },
    // onCompleted: (CartItems) => {
    // 		// setSelected([])

    // 	// setTotalCart(CartItems.extCart.total)
    //   setSelected(CartItems.extCart.lists);
    //   // if (parentPage==='DataInputSubbesteller') setter(CartItems.extCart.lists)
    // }
  });
  const { data: coolBoxForce } = useQuery(CheckCoolBoxForce);

  const [
    setPriceNameMutation2,
    { loading: mutationLoading2, error: mutationError2 },
  ] = useMutation(setPriceNameMutation);

  useEffect(() => {
    if (content && !selected) {
      setSelected(content.extCart.lists);
    }
  }, [content]);

  useEffect(() => {
    if (
      content?.extCart?.coolbox > 0 ||
      coolBoxForce?.generalOptions?.coolbox_shipment?.coolboxForced
    ) {
      setCoolBoxShipping(true);
    }
  }, [content, coolBoxForce]);

  const [checkCouponCodeQuery, { loading: loading2, error: error2, data }] =
    useLazyQuery(CheckCouponCode2);

  const [user, setUser] = useContext(GetUserContext);
  const { selected, setSelected, duration } = useContext(GetCurrentPage);
  const [toggleCoupon, setToggleCoupon] = useState(true);
  const [couponCode, setCouponCode] = useState("");
  const [couponAmount, setCouponAmount] = useState("");
  const [couponType, setCouponType] = useState("");
  const [showCoupon, setShowCoupon] = useState(false);
  const [errorWeight, setErrorWeight] = useState();
  const [showPriceState, setShowPriceState] = useState(user.showPrice);
  const [showNameState, setShowNameState] = useState(user.showName);
  const [coolBoxShipping, setCoolBoxShipping] = useState(false);
  const [showCouponAlert, setShowCouponAlert] = useState(false);
  const [showCouponErrorAlert, setShowCouponErrorAlert] = useState(false);
  const [userFromBackend, setUserFromBackend] = useState();

  //   const [couponAmount, setCouponAmount] = useState();
  //   const [couponType, setCouponType] = useState();

  const couponsRef = useRef();
  const [totalCart, setTotalCart] = useState(0);
  const [reload, setReload] = useState(true);

  useEffect(() => {
    if (user.hasCoupon) {
      setShowCoupon(true);
    } else {
      setShowCoupon(false);
    }
  }, []);

  useEffect(() => {
    if (data) {
      let response = data.checkCoupon;

      if (response.valid) {
        if (response.type === "percent") {
          setCouponType("%");
        } else if (response.type === "fixed_cart") {
          setCouponType("€");
        }
        setCouponAmount(response.amount);
        setToggleCoupon(false);
        setShowCouponAlert(true);
      } else {
        setShowCouponErrorAlert(true);
      }
    }
  }, [data]);

  const CheckValidOrder = async () => {
    if (
      content?.extCart?.totalWeight >=
      (content?.extCart?.minimumOrderWeight || 8)
    ) {
      if (userFromBackend) {
        setUser(userFromBackend);
      }

      await saveOrder(couponCode, coolBoxShipping);
      activeView("OrderComplete");
    } else {
      setErrorWeight(
        `Die Mindestbestellmenge von ${content?.extCart?.minimumOrderWeight} kg wurde nicht erreicht`
      );
    }
  };

  const CheckCoupon = () => {
    let coupon = couponsRef.current.value;

    checkCouponCodeQuery({ variables: { code: coupon } });
    setCouponCode(coupon);
  };

  const saveShowNamePrice = (type, e) => {
    let priceState = showPriceState;
    let nameState = showNameState;
    if (type == "price") {
      priceState = e;
    } else if (type == "name") {
      nameState = e;
    }

    setPriceNameMutation2({
      variables: {
        id: user.id,
        showPrice: priceState,
        showName: nameState,
      },
    })
      .then((response) => {
        setUserFromBackend(response.data.updateCustomer.customer);
        setShowPriceState(response.data.updateCustomer.customer.showPrice);
        setShowNameState(response.data.updateCustomer.customer.showName);
      })
      .catch((error) => {
        if (type == "price") {
          setShowPriceState(!e);
        } else if (type == "name") {
          setShowNameState(!e);
        }
        alert(error.toString());
      });
  };

  const updateCoolbox = (e) => {
    setCoolBoxShipping(e);
    const newVariables = { subordererKey, coolboxDisabled: !e };
    refetch(newVariables);
  };
  // if (loading) return "Loading...";
  if (error) return <Error404 />;
  return (
    <Wrapper>
      <Content>
        {parentPage != "SingleBuyerPage" && (
          <CircleWrapper>
            {duration.toString().includes("Bestelldauer") ? (
              <CircleWithText>{duration} </CircleWithText>
            ) : (
              <CircleWithText size="170" font="18">
                Bestellmöglichkeit: <br />
                {duration.toString().includes("Uhr") ? (
                  <strong> bis {duration}</strong>
                ) : (
                  <>
                    <strong>noch {duration} Tag(e)</strong> offen
                  </>
                )}
              </CircleWithText>
            )}
          </CircleWrapper>
        )}
        <LoginContainer>
          {customer ? (
            <React.Fragment>
              <ContentContainer>
                <HeadingSmall>Rechnungsadresse</HeadingSmall>
                <DataParagraph>
                  {customer.billing.firstname} {customer.billing.lastname}
                </DataParagraph>
                <DataParagraph>{customer.billing.adress}</DataParagraph>
                <DataParagraph>
                  {customer.billing.postcode} {customer.billing.city}
                </DataParagraph>
                <DataParagraph>{customer.billing.country}</DataParagraph>
                {/* <p>{customer.billing.phone}</p>
                <p>{customer.billing.email}</p> */}
              </ContentContainer>
              <ContentContainer>
                <HeadingSmall>Lieferadresse</HeadingSmall>
                <DataParagraph>
                  {customer.shipping.firstnameshipping}{" "}
                  {customer.shipping.lastnameshipping}
                </DataParagraph>
                <DataParagraph>
                  {customer.shipping.adressshipping}
                </DataParagraph>
                <DataParagraph>
                  {customer.shipping.postcodeshipping}{" "}
                  {customer.shipping.cityshipping}
                </DataParagraph>
                <DataParagraph>
                  {customer.shipping.countryshipping}
                </DataParagraph>
              </ContentContainer>
            </React.Fragment>
          ) : (
            <React.Fragment>
              <ContentContainer>
                <HeadingSmall>Rechnungsadresse</HeadingSmall>
                <DataParagraph>
                  {user.billing.firstName} {user.billing.lastName}
                </DataParagraph>
                <DataParagraph>{user.billing.address1}</DataParagraph>
                <DataParagraph>
                  {user.billing.postcode} {user.billing.city}
                </DataParagraph>
                <DataParagraph>{user.billing.country}</DataParagraph>
                {/* <p>{user.billing.phone}</p>
                <p>{user.billing.email}</p> */}
              </ContentContainer>
              <ContentContainer>
                <HeadingSmall>Lieferadresse</HeadingSmall>
                <DataParagraph>
                  {user.shipping.firstName} {user.shipping.lastName}
                </DataParagraph>
                <DataParagraph>{user.shipping.address1}</DataParagraph>
                <DataParagraph>
                  {user.shipping.postcode} {user.shipping.city}
                </DataParagraph>
                <DataParagraph>{user.shipping.country}</DataParagraph>
              </ContentContainer>
            </React.Fragment>
          )}
          <Padding>
            <StyledButton
              onClick={() =>
                activeView(
                  parentPage === "SingleBuyerPage"
                    ? "AddProduct"
                    : parentPage === "ImportOrderPage"
                    ? "Import"
                    : "AddProduct"
                )
              }
              scale={"ORDER"}
              title={"Bestellung ändern"}
              color={"#CCB242"}
              fontSize={"20px"}
            />
            <div style={{ height: "5px" }} />
            <StyledButton
              onClick={() => CheckValidOrder()}
              scale={"ORDER"}
              title={"Zahlungspflichtig bestellen"}
              color={"#B2982A"}
              fontSize={"20px"}
              error={errorWeight}
              loading={loadingExtCart}
            />
          </Padding>
        </LoginContainer>

        <ListSelectWrapper
          style={{
            display: "flex",
            flex: 4,
            gap: "5vw",
            height: "fit-content",
            alignItems: "center",
          }}
        >
          {!loadingExtCart ? (
            <>
              <ListWrapper>
                {selected && selected.length != 0 && (
                  <>
                    <AccordionComp
                      data={selected}
                      cart={content?.extCart}
                      parentPage={"OrderOverview"}
                    />
                  </>
                )}
                {!loadingExtCart &&
                  parentPage != "SingleBuyerPage" &&
                  selected &&
                  selected.length != 0 && (
                    <Paragraph className="mobile">
                      {duration.toString().includes("Bestelldauer") ? (
                        <strong>{duration}</strong>
                      ) : (
                        <>
                          Die Bestellung ist noch&nbsp;
                          {duration.toString().includes("Uhr") ? (
                            <strong> bis {duration}</strong>
                          ) : (
                            <strong>{duration} Tage</strong>
                          )}{" "}
                          &nbsp; offen.
                        </>
                      )}
                    </Paragraph>
                  )}

                {user.hasCoupon && (
                  <div style={{ display: "wrap" }}>
                    {couponAmount && couponType && (
                      <Paragraph style={{ marginTop: "50px" }}>
                        Mit diesem Coupon erhälst du{" "}
                        <strong>
                          {couponAmount} {couponType}{" "}
                        </strong>
                        auf den <strong>NETTO-Preis!</strong>
                      </Paragraph>
                    )}

                    {parentPage !== "ImportOrderPage" &&
                      selected &&
                      selected.length != 0 &&
                      toggleCoupon &&
                      showCoupon && (
                        <React.Fragment>
                          <RefInput
                            forwardedRef={couponsRef}
                            type={"text"}
                            placeholder={"Gutschein-Code einlösen"}
                            maxLength="40"
                          ></RefInput>
                          <StyledButton
                            onClick={() => CheckCoupon()}
                            scale={"ORDER"}
                            title={"Gutschein einlösen"}
                            color={colors.YellowBrigthButton}
                            fontSize={"20px"}
                            loading={loading2}
                          />
                        </React.Fragment>
                      )}
                  </div>
                )}
              </ListWrapper>
              {reload &&
                selected &&
                !loadingExtCart &&
                selected.length != 0 && (
                  <SelectContainer>
                    <SelectWrapper>
                      {parentPage === "SingleBuyerPage" ? (
                        <SingleSelect
                          fontSize="16"
                          checked={showPriceState}
                          onChange={(e) => {
                            saveShowNamePrice("price", e);
                          }}
                          caption={"Preis auf Etikett drucken"}
                        />
                      ) : (
                        <>
                          <SingleSelect
                            fontSize="16"
                            checked={showPriceState}
                            onChange={(e) => {
                              saveShowNamePrice("price", e);
                            }}
                            caption={"Preis auf Etikett drucken"}
                          />
                          <SingleSelect
                            fontSize="16"
                            checked={showNameState}
                            onChange={(e) => {
                              saveShowNamePrice("name", e);
                            }}
                            caption={"Name auf Etikett drucken"}
                          />
                        </>
                      )}
                      {content?.extCart?.coolboxRecommended && (
                        <SingleSelect
                          fontSize="16"
                          checked={coolBoxShipping}
                          disabled={
                            coolBoxForce?.generalOptions?.coolbox_shipment
                              ?.coolboxForced
                          }
                          onChange={(e) => {
                            updateCoolbox(e);
                          }}
                          caption={"Lieferung in Kühlbox"}
                        />
                      )}
                    </SelectWrapper>
                  </SelectContainer>
                )}
            </>
          ) : (
            <ThreeDots
              height={200}
              width={100}
              color={colors.YellowBrigthButton}
            />
          )}
        </ListSelectWrapper>

        {(showCouponAlert || showCouponErrorAlert) && (
          <Alert isVisible={showCouponAlert || showCouponErrorAlert}>
            <ParagraphCenter>
              {showCouponErrorAlert
                ? "Gutschein-Code nicht gültig"
                : "Coupon erfolgreich eingesetzt"}
            </ParagraphCenter>
            <ImageWrapper>
              <ImageLogo alt={"test"} src={Brand}></ImageLogo>
              <div style={{ height: "20px" }} />
            </ImageWrapper>
            <div
              style={{
                width: "100%",
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                marginTop: "20px",
              }}
            >
              <StyledButton
                onClick={() => {
                  setShowCouponAlert(false);
                  setShowCouponErrorAlert(false);
                }}
                scale={"ORDER"}
                title={"OK"}
                color="#CCB242"
                fontSize={"20px"}
              />
            </div>
          </Alert>
        )}
      </Content>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  /* margin-left: 10vw; */
  width: 100%;
  flex-direction: row;
`;
const ListSelectWrapper = styled.div`
  display: flex;
  flex: 5;
  gap: 5vw;
  height: fit-content;
  align-items: center;
  @media (max-width: 1200px) {
    flex-direction: column;
  }
`;
const ListWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 3;
  height: fit-content;
`;

const Content = styled.div`
  width: 100%;
  /* height: 80vh; */
  display: flex;
  flex-direction: row;
  /* margin-top: 40px; */
  /* justify-content: space-around; */
  padding: 0 50px;
  background-color: #ffffff;
  justify-content: space-between;
  gap: 5vw;

  @media (max-width: 850px) {
    flex-direction: column;

    padding: 0 20px;
    align-items: center;
  }
  @media (max-width: 500px) {
    width: 95vw;
    padding: 0;
  }
`;

const LoginContainer = styled.div`
  padding: 20px 0;
  width: 241px;
  display: flex;
  flex: 1;
  flex-direction: column;
`;

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;

  p {
    margin: 0;
  }
`;
const ParagraphCenter = styled.p`
  color: #808080;
  font-size: 16px;
  letter-spacing: 0.4px;
  font-family: "Montserrat";
  font-weight: 300;
  padding: 0;
  text-align: center;
`;
const ImageWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
`;
const ImageLogo = styled.img`
  width: 312px;
  justify-content: center;
`;
const SelectContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex: 2;
  height: fit-content;
`;
const SelectWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: fit-content;
`;
const Padding = styled.div`
  padding: 25% 0;
  @media (max-width: 770px) {
    padding-bottom: 0;
    padding-top: 30px;
  }
`;

const HeadingSmall = styled.h4`
  font-family: "Montserrat";
  font-weight: 300;
  color: #4d4d4d;
  font-size: 16px;
  letter-spacing: 0.4px;
`;

const DataParagraph = styled.p`
  font-family: "Montserrat";
  font-weight: 300;
  color: #808080;
  font-size: 16px;
  letter-spacing: 0.4px;
`;

const CircleWrapper = styled.div`
  position: absolute;
  top: 17vh;
  right: 1vw;
  cursor: pointer;

  @media (min-width: 1700px) {
    top: 150px;
    left: 1500px;
    right: unset;
  }
  @media (max-width: 1270px) {
    display: none;
  }
`;

const Paragraph = styled.p`
  font-size: 14px;
  font-family: "Montserrat", sans-serif;
  font-weight: 200;
  &.mobile {
    display: none;
    @media (max-width: 1270px) {
      display: flex;
    }
  }
`;
export default OrderOverview;
