// Created by:      Marcel Simma
// Copyright:       © 2020 Zewas OG
// Creation date:   02.08.2020
// Last edited:     02.08.2020

import React, { useContext } from "react";
import { useLocation } from "react-router-dom";
import styled from "styled-components";
import Cookies from "universal-cookie";
import colors from "../../constants/colors";
import constants from "../../constants/constants";
import { GetUserContext } from "../../localStorage/Store";
import styl from "../../styl";
const cookies = new Cookies();
// Props:
// - href: String (required)
// -- The link to another page.
// - icon: String (not required)
// -- Contains a picture to be displayed in front of the text.
// - text: String (required)
// -- A word that tells the user what he will see when he clicks on the link.
// - fontWeight: String (not required)
// -- This can be used to set the font-weight of the link to bold.  By default the font-weight is light.

// Example: <LoginButton href={"/login"} text={"Login"} fontWeight={"bold"} icon={LoginIcon}/>

const LoginButton = ({ href, icon, text, fontWeight, onClick }) => {
  const [user, setUser] = useContext(GetUserContext);
  const logout = () => {
    if (href === "/home") {
      setUser(null);
      cookies.remove("AuthToken", { path: "/" });
      cookies.remove("RefreshToken", { path: "/" });
      //remove all cookies from all paths
      var cookies2 = document.cookie.split("; ");
      for (var c = 0; c < cookies2.length; c++) {
        var d = window.location.hostname.split(".");
        while (d.length > 0) {
          var cookieBase =
            encodeURIComponent(cookies2[c].split(";")[0].split("=")[0]) +
            "=; expires=Thu, 01-Jan-1970 00:00:01 GMT; domain=" +
            d.join(".") +
            " ;path=";
          var p = window.location.pathname.split("/");
          document.cookie = cookieBase + "/";
          while (p.length > 0) {
            document.cookie = cookieBase + p.join("/");
            p.pop();
          }
          d.shift();
        }
      }
    }
  };

  return (
    <Container
      onClick={() => {
        logout();
        onClick();
      }}
      prop_icon={icon}
      className={useLocation().pathname === href ? "sel" : null}
      href={href}
    >
      {icon ? <Icon prop_icon={icon} /> : null}
      <Text prop_icon={icon} $prop_fontWeight={fontWeight}>
        {text}
      </Text>
    </Container>
  );
};

const Container = styled.a`
  ${styl.clear};
  ${styl.flexRow};
  align-items: center;
  box-sizing: border-box;
  ${styl.transition};
  margin: 0px;
  cursor: pointer;
  height: 100%;
  margin-left: 10px;
  margin-right: 60px;

  @media (max-width: 770px) {
    margin-right: 10px;
  }

  &.sel {
    p {
      font-weight: 600;
    }
  }

  &:active,
  &:hover {
    p {
      color: ${colors.CheninYellowHoverButton};
    }
  }
`;

const Text = styled.p`
  ${styl.flexRow};
  ${styl.flexCenter};
  ${styl.clear};
  ${styl.transition};
  text-decoration: none;
  color: ${colors.SolidGreyText};
  ${(props) =>
    props.$prop_fontWeight == "bold"
      ? styl.boldDefaultFont
      : styl.lightDefaultFont}
  font-size: 15px;
  text-transform: uppercase;

  @media (max-width: ${constants.nexusLandscapeWidth}px) {
    font-size: 11px;
  }

  @media (max-width: ${constants.nexusPortraitWidth}px) {
    font-size: 14px;
  }
`;

const Icon = styled.div`
  ${styl.clear};
  height: 24px;
  width: 24px;
  display: flex;
  background: url(${(props) => props.prop_icon});
  background-size: contain;
  background-position: center;
  margin-right: 10px;

  @media (max-width: ${constants.nexusPortraitWidth}px) {
    font-size: 14px;
  }

  @media (max-width: ${constants.iPhonePortraitWidth}px) {
    margin: 0px 10px;
    width: 28px;
    height: 28px;
  }
`;

export default LoginButton;
