import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import colors from "../constants/colors";
import constants from "./../constants/constants";

// Props:
// - title: String (required)
// - color: oneOf
// -- "DEFAULT": golden line (defaultvalue)
// -- "BLUE": blue line
// - underlin: true/false

const HeadingTwo = ({ title, color = "BLUE", underline = "true" }) => {
  return (
    <Wrapper>
      <H2 $prop_Color={color}>{title}</H2>
      <BottomLine $prop_Color={color} $prop_Underline={underline} />
    </Wrapper>
  );
};

HeadingTwo.propTypes = {
  title: PropTypes.string.isRequired,
  color: PropTypes.oneOf(["DEFAULT", "BLUE", "WHITE"]),
  underline: PropTypes.oneOf(["true", "false"]),
};

const Wrapper = styled.div`
  text-align: left;
  display: inline-block;
`;

const H2 = styled.h2`
  color: ${(props) =>
    props.$prop_Color === "DEFAULT"
      ? colors.CongressBlueHeader
      : props.$prop_Color === "BLUE"
      ? colors.CongressBlueHeader
      : props.$prop_Color === "WHITE"
      ? colors.SolidWhiteBackground
      : colors.OldgoldYellowLogo};
  font-size: 30px;
  font-family: "Sorts Mill Goudy", serif;
  font-weight: 10;
  line-height: 56px;
  margin: 0;
  /* margin-bottom: 0px; */

  @media (max-width: 1900px) {
    font-size: 24px;
    line-height: 28px;
  }

  @media (max-width: 1550px) {
    font-size: 22px;
    line-height: 28px;
  }

  @media (max-width: 1300px) {
    font-size: 20px;
    line-height: 28px;
  }

  @media (max-width: ${constants.nexusLandscapeWidth}px) {
    font-size: 24px;
    line-height: 30px;
  }

  @media (max-width: ${constants.nexusPortraitWidth}px) {
    font-size: 22px;
    line-height: 23px;
  }
`;

const BottomLine = styled.div`
  display: ${(props) => (props.$prop_Underline === "false" ? "none" : "block")};
  width: 65px;
  border-bottom: 2px solid
    ${(props) =>
      props.$prop_Color === "DEFAULT"
        ? colors.OldgoldYellowLogo
        : props.$prop_Color === "BLUE"
        ? colors.CongressBlueHeader
        : props.$prop_Color === "WHITE"
        ? colors.SolidWhiteBackground
        : colors.SolidWhiteBackground};

  @media (max-width: ${constants.nexusLandscapeWidth}px) {
    width: 40px;
  }

  @media (max-width: ${constants.nexusPortraitWidth}px) {
    width: 35px;
  }
`;

export default HeadingTwo;
